import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'reactstrap';

// --- Components --- //
import Loading from '../../../components/common/LoadingIndicator';

// --- Icons --- //
import RdsRemoveIcon from '../../../icons/RdsRemoveIcon';
import AddIcon from '../../../icons/AddIcon';

// --- Actions --- //
import * as imgActionCreator from '../../../actions/imgActionCreator';
import classNames from 'classnames';
import I18N from '../../../i18n';
import RDSButton from '../RDSButton';

class UserAvatarModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            avatar: null,
            showLoading: false,
        };
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleCbConfirmAction = this.handleCbConfirmAction.bind(this);
    }


    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleSelectFile(e) {
        e.preventDefault();
        e && e.stopPropagation();
        const { imgActions } = this.props;
        const _this = this;
        [...e.target.files].forEach((fileInfo, index) => {
            let reads = new FileReader();
            reads.readAsDataURL(fileInfo);
            reads.onload = function (event) {
                const imgUrl = this.result

                _this.toggleLoading();
                imgActions && imgActions.uploadImgToGetUrl(imgUrl)
                    .then(url => {
                        _this.toggleLoading();

                        _this.setState({ avatar: url });
                        event.target.value = '';
                    })
                    .catch((err) => {
                        console.error(err);
                        _this.toggleLoading();
                        event.target.value = '';
                    });
            };

        });
    }

    handleCbConfirmAction() {
        const {confirmAction} = this.props;
        confirmAction && confirmAction(this.state.avatar);
    }

    render() {
        const { showLoading, avatar } = this.state;
        const {toggle} = this.props;
        return (<div className='tw-w-full tw-h-full tw-bg-[rgba(0,0,0,0.6)] tw-fixed tw-left-0 tw-top-0 tw-flex tw-items-center tw-justify-center tw-z-[1000]'>
            {showLoading && <Loading />}
            <div className='tw-w-[500px] tw-h-[500px] tw-bg-white tw-rounded-[30px] tw-px-[30px] tw-py-[30px] animate__animated animate__bounceInDown'>
                <div className='tw-text-center tw-text-[20px] tw-font-bold tw-mb-[30px]'>{I18N.getText('upload-avatar')}</div>
                <div className={classNames('tw-mx-auto tw-w-[300px] tw-h-[300px] tw-mb-[20px] tw-bg-background-3 tw-rounded-[15px] tw-border-[2px] tw-border-dashed tw-border-body-text-2 tw-relative hs-payout-certificate')}>
                    <AddIcon size={40} className="tw-absolute tw-left-[50%] tw-top-[50%] tw-ml-[-20px] tw-mt-[-20px] tw-z-[3]" />
                    <Input className='tw-z-[2] tw-relative tw-w-full tw-h-full tw-opacity-0 ' type='file' onChange={this.handleSelectFile} accept="image/*" />
                    {avatar && <div style={{ backgroundImage: `url(${avatar})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className='tw-w-full tw-h-full tw-bg-cover tw-rounded-[15px] tw-absolute tw-top-0 tw-left-0 tw-z-[4]' />}
                    {avatar && <RdsRemoveIcon className="tw-absolute tw-right-[-10px] tw-bottom-[-10px] tw-z-[5]" color='#ACACAC' onClick={this.removeImg} />}
                </div>
                <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                    <RDSButton onClick={toggle} color='white'>{I18N.getText('cancel')}</RDSButton>
                    <RDSButton onClick={this.handleCbConfirmAction} color='primary' className="tw-ml-[100px] tw-text-white">{I18N.getText('submit')}</RDSButton>
                </div>
            </div>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        imgActions: bindActionCreators(imgActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatarModal);

