import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Input } from 'reactstrap';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import LocaleCalendar from '../../components/common/LocaleCalendar';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Selectors --- //
import * as UserSelectors from '../../selectors/UserSelectors';

// --- Actions --- //
import * as userActionCreator from '../../actions/userActionCreator';
import * as rechargeActionCreator from '../../actions/rechargeActionCreator';

// --- Icons --- //
import AddIcon from '../../icons/AddIcon';
import XIcon from '../../icons/XIcon';
import RdsRemoveIcon from '../../icons/RdsRemoveIcon';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';


import I18N from '../../i18n';
import UserHelper from '../../helpers/UserHelper';
import { PAYMENT_ACCOUNT_STATE_MAP, PAYMENT_ACCOUNT_STATE_MAP_FOR_ADMIN, PAYMENT_ACCOUNT_STATE_MAP_FOR_FOREIGNER } from '../../constants/BasicDataConstants';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';
import { getMMDDFormattedDate } from '../../utils/DateTimeUtil';
import moment from 'moment';


class RechargePage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedUser: null,
            rechargeAmount: '',
            note: '',

            showError: false,
            showLoading: false,
            errMsg: '',
            errFiled: '',
            isEditing: false,
        };

        this.handleBackPage = this.handleBackPage.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleFormPropertyChange = this.handleFormPropertyChange.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.editSettleInfo = this.editSettleInfo.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.getUnpagedUserList = this.getUnpagedUserList.bind(this);
    }

    componentDidMount() {
        this.getUnpagedUserList();
    }

    getUnpagedUserList(callback) {
        const { userActions } = this.props;
        userActions && userActions.fetchUnpagedUserList()
            .then(() => {
                callback && callback.call(this);
            })
            .catch((err) => console.error(err));
    }

    handleFormPropertyChange(e, property) {
        const value = e && e.target.value;
        if (property === 'rechargeAmount') {
            const regx = new RegExp(/^([-+]?)([0-9]|[1-9]\d+)?(\.[0-9]{0,2})?$/);
            if (regx.test(value) || value === '') {
                this.setState({ [property]: value, showError: false });
            }
        } else {
            this.setState({ [property]: value, showError: false });
        }
    }


    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }


    handleUserChange(selectedUser) {
        this.setState({ selectedUser, selectedPaymentAccount: null, showError: false });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    editSettleInfo() {
        let { note, selectedUser, rechargeAmount } = this.state;
        const { rechargeActions, history } = this.props;
        let params = {
            userid: selectedUser && selectedUser.value,
            ZhangDanJinE: rechargeAmount,
            ShuoMing: note
        };

        this.toggleEditing();
        rechargeActions && rechargeActions.createRechargeInfo(params)
            .then(() => {
                this.toggleEditing();
            })
            .catch(() => {
                this.toggleEditing();
            })

    }

    render() {
        const { showError, errMsg, isEditing, showLoading, selectedUser, rechargeAmount, note } = this.state;
        const { unpagedUserList } = this.props;
        const disabledBtn = isEditing || !selectedUser || rechargeAmount.trim() === '' || parseFloat(rechargeAmount) === 0;

        return <div className='tw-w-full tw-px-[30px] tw-py-[30px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                {/* <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div> */}
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('user')}</div>
                        <Select
                            className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container")}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedUser}
                            placeholder={I18N.getText('user-selector-tip')}
                            onChange={this.handleUserChange}
                            name="user"
                            options={unpagedUserList}
                        />
                    </div>

                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('recharge-amount')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('settlement-amount-tip')}
                            value={rechargeAmount}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'rechargeAmount')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('note')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('note-tip')}
                            value={note}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'note')}
                        />
                    </div>


                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={disabledBtn}
                            onClick={this.editSettleInfo}
                            className="tw-text-white" >{I18N.getText('create')}</RDSButton>
                    </div>

                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        currentUser: UserSelectors.selectCurrentUser(state),
        unpagedUserList: UserSelectors.selectUnpagedUserList(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActionCreator, dispatch),
        rechargeActions: bindActionCreators(rechargeActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RechargePage);