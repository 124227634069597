import I18N from "../i18n";

export const ROLE_MAP = {
  1: I18N.getText('customer'),
  2: I18N.getText('registrant'),
  3: I18N.getText('admin')
}

export const PAYMENT_ACCOUNT_STATE_MAP = [
  { value: 0, label: I18N.getText('awaiting-assignment') },
  { value: 1, label: I18N.getText('awaiting-registration') },
  { value: 2, label: I18N.getText('awaiting-approval') },
  { value: 3, label: I18N.getText('done') },
  { value: 4, label: I18N.getText('failed'), disabled: true },
];

export const PAYMENT_ACCOUNT_STATE_MAP_FOR_ADMIN = [
  { value: 0, label: I18N.getText('awaiting-assignment'), disabled: true },
  { value: 1, label: I18N.getText('awaiting-registration') },
  { value: 2, label: I18N.getText('awaiting-approval') },
  { value: 3, label: I18N.getText('done') },
  { value: 4, label: I18N.getText('failed') },
];

export const PAYMENT_ACCOUNT_STATE_MAP_FOR_FOREIGNER = [
  { value: 0, label: I18N.getText('awaiting-assignment'), disabled: true },
  { value: 1, label: I18N.getText('awaiting-registration'), disabled: true },
  { value: 2, label: I18N.getText('awaiting-approval') },
  { value: 3, label: I18N.getText('done'), disabled: true },
  { value: 4, label: I18N.getText('failed'), disabled: true },
];