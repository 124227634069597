import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Input } from 'reactstrap';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import LocaleCalendar from '../../components/common/LocaleCalendar';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Selectors --- //
import * as UserSelectors from '../../selectors/UserSelectors';

// --- Actions --- //
import * as paymentAccountActionCreator from '../../actions/paymentAccountActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';
import * as imgActionCreator from '../../actions/imgActionCreator';
import * as billingActionCreator from '../../actions/billingActionCreator';
import * as settleActionCreator from '../../actions/settleActionCreator';

// --- Icons --- //
import AddIcon from '../../icons/AddIcon';
import XIcon from '../../icons/XIcon';
import RdsRemoveIcon from '../../icons/RdsRemoveIcon';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';


import I18N from '../../i18n';
import UserHelper from '../../helpers/UserHelper';
import { PAYMENT_ACCOUNT_STATE_MAP, PAYMENT_ACCOUNT_STATE_MAP_FOR_ADMIN, PAYMENT_ACCOUNT_STATE_MAP_FOR_FOREIGNER } from '../../constants/BasicDataConstants';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';
import { getMMDDFormattedDate } from '../../utils/DateTimeUtil';
import moment from 'moment';


class NewSettlePage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedCustomer: null,
            selectedPaymentAccount: null,
            paymentAccountList: [],
            settlementAmount: '',
            note: '',

            showError: false,
            showLoading: false,
            errMsg: '',
            errFiled: '',
            isEditing: false,
        };

        this.handleBackPage = this.handleBackPage.bind(this);
        this.getUnpagedPaymentAccount = this.getUnpagedPaymentAccount.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handlePaymentAccountChange = this.handlePaymentAccountChange.bind(this);

        this.handleFormPropertyChange = this.handleFormPropertyChange.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.editSettleInfo = this.editSettleInfo.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.getPaymentAccountById = this.getPaymentAccountById.bind(this);
        this.getUnpagedCustomerList = this.getUnpagedCustomerList.bind(this);
        this.getUnpagedPaymentAccount = this.getUnpagedPaymentAccount.bind(this);
        this.fetchBasicSelectorData = this.fetchBasicSelectorData.bind(this);
    }

    componentDidMount() {
        this.fetchBasicSelectorData();
    }

    fetchBasicSelectorData() {
        this.getUnpagedCustomerList(this.getUnpagedPaymentAccount);
    }

    getUnpagedCustomerList(callback) {
        const { userActions } = this.props;
        userActions && userActions.fetchUnpagedUserList()
            .then(() => {
                callback && callback.call(this);
            })
            .catch((err) => console.error(err));
    }


    getUnpagedPaymentAccount(customerId) {
        // this.toggleLoading();
        const { paymentAccountActions } = this.props;
        const params = customerId ? { YongYouZheID: customerId } : null
        paymentAccountActions && paymentAccountActions.getUnpagedPaymentAccountList(params)
            .then(data => {
                // this.toggleLoading();
                const formatPaymentAccountList = data && data.map(item => {
                    return { value: item.id, label: item.ZhangHao, type: item.ZhangHaoZhongLei };
                });
                this.setState({ paymentAccountList: formatPaymentAccountList })
                this.getPaymentAccountById();
            })
            .catch(() => {
                // this.toggleLoading();
            });
    }

    getPaymentAccountById() {
        const { location, paymentAccountActions, unpagedCustomerList } = this.props;
        const paymentAccountId = location.pathname.split(`${PagePathConstants.SETTLE_ACCOUNT}/`)[1];
        console.error(paymentAccountId)
        if (paymentAccountId && paymentAccountId !== 'add') {
            this.toggleLoading();
            paymentAccountActions.getPaymentAccountInfoById(paymentAccountId)
                .then(data => {
                    const filterSelectedCustomer = unpagedCustomerList.filter(item => item.value === data.YongYouZheID);
                    const filterSelectedPaymentAccount = this.state.paymentAccountList.filter(item => item.value === data.id);
                    this.setState({
                        selectedPaymentAccount: filterSelectedPaymentAccount.length > 0 ? filterSelectedPaymentAccount[0] : this.state.selectedPaymentAccount,
                        selectedCustomer: filterSelectedCustomer.length > 0 ? filterSelectedCustomer[0] : this.state.selectedCustomer,
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }

    }

    handleFormPropertyChange(e, property) {
        const value = e && e.target.value;
        if (property === 'settlementAmount') {
            const regx = new RegExp(/^([-+]?)([0-9]|[1-9]\d+)?(\.[0-9]{0,2})?$/);
            if (regx.test(value) || value === '') {
                this.setState({ [property]: value, showError: false });
            }
        } else {
            this.setState({ [property]: value, showError: false });
        }
    }


    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }


    handleCustomerChange(selectedCustomer) {
        this.setState({ selectedCustomer, selectedPaymentAccount: null, showError: false }, () => {
            this.getUnpagedPaymentAccount(selectedCustomer.value);
        });
    }

    handlePaymentAccountChange(selectedPaymentAccount) {
        this.setState({ selectedPaymentAccount, showError: false });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    editSettleInfo() {
        let { note, selectedPaymentAccount, settlementAmount } = this.state;
        const { settleActions, history } = this.props;
        let params = {
            ShuoMing: note,
            ZhangHaoID: selectedPaymentAccount && selectedPaymentAccount.value,
            ZhangDanJinE: settlementAmount
        };

        this.toggleEditing();
        settleActions && settleActions.createSettleInfo(params)
            .then(() => {
                this.toggleEditing();
                history && history.push(PagePathConstants.SETTLE_ACCOUNT);

            })
            .catch(() => {
                this.toggleEditing();
            })

    }

    render() {
        // const { showError, errMsg, isEditing, showLoading, selectPaymentAccount, paymentAccountList, billingAmount, note, billingImg, isStartDateCalendarOpen, selectedBillingDate } = this.state;
        const { showError, errMsg, isEditing, showLoading, selectedCustomer, paymentAccountList, selectedPaymentAccount, settlementAmount, note } = this.state;
        const { location, unpagedCustomerList } = this.props;
        
        const disabledBtn = isEditing || !selectedPaymentAccount || !selectedCustomer || settlementAmount.trim() === '' || parseFloat(settlementAmount) === 0;

        return <div className='tw-w-full tw-px-[30px] tw-py-[30px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('customer')}</div>
                        <Select
                            className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container")}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedCustomer}
                            placeholder={I18N.getText('customer-selector-tip')}
                            onChange={this.handleCustomerChange}
                            name="account"
                            options={unpagedCustomerList}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('account')}</div>
                        <Select
                            className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container")}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedPaymentAccount}
                            placeholder={I18N.getText('account-selector-tip')}
                            onChange={this.handlePaymentAccountChange}
                            name="account"
                            options={paymentAccountList}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('settlement-amount')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('settlement-amount-tip')}
                            value={settlementAmount}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'settlementAmount')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('note')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('note-tip')}
                            value={note}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'note')}
                        />
                    </div>


                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={disabledBtn}
                            onClick={this.editSettleInfo}
                            className="tw-text-white" >{I18N.getText('create')}</RDSButton>
                    </div>

                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        currentUser: UserSelectors.selectCurrentUser(state),
        unpagedForeignerList: UserSelectors.selectUnpagedForeignerList(state),
        unpagedCustomerList: UserSelectors.selectUnpagedCustomerList(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        paymentAccountActions: bindActionCreators(paymentAccountActionCreator, dispatch),
        userActions: bindActionCreators(userActionCreator, dispatch),
        imgActions: bindActionCreators(imgActionCreator, dispatch),
        billingActions: bindActionCreators(billingActionCreator, dispatch),
        settleActions: bindActionCreators(settleActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSettlePage);