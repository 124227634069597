import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Input } from 'reactstrap';

// --- Components --- //
import RDSButton from '../components/common/RDSButton';
import SvgIcon from '../components/common/SvgIcon';

// --- Constants --- //
import PagePathConstants from '../constants/router/PagePathConstants';

// --- Actions --- //
import * as userActionCreator from '../actions/userActionCreator';

// --- Selectors --- //
import * as UserSelectors from '../selectors/UserSelectors';
import '../styles/pages/loginPage.css';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import classNames from 'classnames';

class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: '',
      code: '',
      showLoginError: false,
      isLogining: false,
      showPasswordEyeIcon: false,
    };

    this.handleLoginAction = this.handleLoginAction.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleGetVerificationCode = this.handleGetVerificationCode.bind(this);
    this.toggleLoginErrorStatus = this.toggleLoginErrorStatus.bind(this);
    this.togglePasswordEyeIcon = this.togglePasswordEyeIcon.bind(this);
    this.toggleLoginStatus = this.toggleLoginStatus.bind(this);
    this.toggleCodeLoadingStatus = this.toggleCodeLoadingStatus.bind(this);
  }

  componentDidMount() {
    // const {loginStatus, history} = this.props;
    // const login = (loginStatus === LOGIN_CONSTANTS.LOGGED_IN);
    // login && history && history.replace(PagePathConstants.HOME_PAGE);
  }

  togglePasswordEyeIcon() {
    this.setState({ showPasswordEyeIcon: !this.state.showPasswordEyeIcon });
  }

  toggleLoginStatus() {
    this.setState({ isLogining: !this.state.isLogining });
  }

  toggleCodeLoadingStatus() {
    this.setState({ isCodeLoading: !this.state.isCodeLoading });
  }

  handleLoginAction() {
    const { email, password, code } = this.state;
    const { userActions, history } = this.props;
    this.setState({ showLoginError: false });
    this.toggleLoginStatus();
    userActions && userActions.loginUser(email, password, code)
      .then(() => {
        this.toggleLoginStatus();
        history && history.push(PagePathConstants.ROOT_PAGE);
      })
      .catch(() => {
        this.toggleLoginStatus();
        this.toggleLoginErrorStatus();
      });
  }

  toggleLoginErrorStatus() {
    this.setState({ showLoginError: !this.state.showLoginError });
  }

  handleEmailChange(event) {
    this.toggleLoginErrorStatus();
    const email = event.currentTarget.value;
    this.setState({ email, showLoginError: false });
  }

  handlePasswordChange(event) {
    this.toggleLoginErrorStatus();
    const password = event.currentTarget.value;
    this.setState({ password, showLoginError: false });
  }

  handleCodeChange(event) {
    this.toggleLoginErrorStatus();
    const code = event.currentTarget.value;
    this.setState({ code, showLoginError: false });
  }

  handleGetVerificationCode() {
    const { email } = this.state;
    const { userActions, history } = this.props;
    this.setState({ showLoginError: false });
    this.toggleCodeLoadingStatus();
    userActions && userActions.getVerificationCode(email)
      .then(() => {
        this.toggleCodeLoadingStatus();
      })
      .catch(() => {
        this.toggleCodeLoadingStatus();
        this.toggleLoginErrorStatus();
      });
  }

  render() {
    const { email, password, code, showLoginError, showPasswordEyeIcon, isLogining, isCodeLoading } = this.state;
    return (
      <div className='tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center hs-login-page-container'>
        <div className="login-container">
          <div className="login-form">
            <div className="login-form-inner">
              <h1 className='tw-text-center  tw-font-bold'>Login</h1>
              <p className="body-text">See your growth and get consulting support!</p>

              <div className="sign-in-seperator">
                <span> Sign in with Account</span>
              </div>

              <div className="login-form-group">
                <label for="email">Account <span className="required-star">*</span></label>
                <input type="text" value={email} placeholder="email@website.com" onChange={this.handleEmailChange} />
              </div>
              <div className="login-form-group tw-relative">
                <label for="pwd">Password <span className="required-star">*</span></label>
                <input type={showPasswordEyeIcon ? "text" : "password"} value={password} placeholder="Minimum 8 characters" onChange={this.handlePasswordChange} />
                <div className='tw-absolute tw-right-[10px] tw-bottom-[8px] tw-right-0 tw-mt-[-8px]' onClick={this.togglePasswordEyeIcon}>
                  {showPasswordEyeIcon ? <SvgIcon name="eye-icon" size={30} /> : <SvgIcon name="eye-off-icon" size={30} />}
                </div>
              </div>

              <div className="login-form-group tw-relative">
                <label for="pwd">Code <span className="required-star">*</span></label>
                <input type="text" value={code} placeholder="Verification code" onChange={this.handleCodeChange} />
                <RDSButton disabled={isCodeLoading} loading={isCodeLoading} size='md' rectangle className={classNames('!tw-w-auto !tw-bg-[#2d2d2d] tw-rounded-[30px] tw-text-white tw-absolute tw-right-[10px] tw-bottom-[7px] tw-right-0 tw-mt-[-8px]')} onClick={this.handleGetVerificationCode}>Get Code</RDSButton>
              </div>

              <div className="login-form-group single-row tw-text-right">

                <div className="link forgot-link">Forgot Password ?</div>
              </div>
              <RDSButton loading={isLogining} className="rounded-button login-cta" onClick={this.handleLoginAction} disabled={isLogining || email.trim() === '' || password.trim() === '' || code.trim() === '' }>Login</RDSButton>

              <div className="register-div">Not registered yet? <span className="link create-account" >Create an account ?</span></div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

function mapStateToProps(state) {
  return {
    loginStatus: UserSelectors.selectLoginStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

