import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap'
import classnames from 'classnames';
import '../../styles/SmallLoading.scss';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.title = props.title;
    this.state = {
      loading: props.loading || 0
    }
    this.setTimeoutTimer = null;
  }

  componentDidMount() {
    const loaders = document.querySelectorAll('.small-loading-icon-container');
    loaders.forEach((loader, index) => {
      if (index > 0) {
        loader.style.display = 'none';
      }
    });
    if (this.state.loading > 0) {
      this.setTimeoutTimer = setTimeout(() => {
        this.setState({ loading: 0 })
      }, this.state.loading);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setTimeoutTimer);
  }

  render() {
    const { className = '' } = this.props
    if (this.state.loading) {
      return null;
    }
    return (
      <div className={classnames('small-loading-icon-container', className)}>
        <div id="small-preloader">
              <div class="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
      </div>
    );
  }
}

export default Loading;
