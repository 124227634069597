import ActionConstants from '../constants/actions/ActionConstants';
import * as RechargeAPI from '../apis/RechargeAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';


export const createRechargeInfo = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            RechargeAPI.createRechargeInfo(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('create-recharge-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('create-recharge-info-failed'));
                    return reject(err)
                });
        });
    };
}
