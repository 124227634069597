import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import { RouteReducer } from './RouteReducer';
import { GlobalAlertReducer } from './GlobalAlertReducer';
import { DashboardReducer } from './DashboardReducer';
import { UserReducer } from './UserReducer';
import { PaymentAccountReducer } from './PaymentAccountReducer';
import { BillingListReducer } from './BillingListReducer';

const appReducer = combineReducers({
  [ReducerNameConstants.ROUTE]: RouteReducer,
  [ReducerNameConstants.GLOBAL_ALERT]: GlobalAlertReducer,
  [ReducerNameConstants.USER]: UserReducer,
  [ReducerNameConstants.PAYMENT_ACCOUNT]: PaymentAccountReducer,
  [ReducerNameConstants.BILLING]: BillingListReducer,
  [ReducerNameConstants.DASHBOARD]: DashboardReducer,

  router: routerReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  return appReducer(newState, action);
};

export default rootReducer;
