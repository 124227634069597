import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectBillingReducer = state => state.get(ReducerNameConstants.BILLING);

export const selectBillingList = createSelector(
  [selectBillingReducer],
  (billingReducer) => {
    return billingReducer && billingReducer.get(StoreStateConstants.BILLING_LIST);
  },
);


export const selectBillingRecordTotalNum = createSelector(
  [selectBillingReducer],
  (billingReducer) => {
    return billingReducer && billingReducer.get(StoreStateConstants.BILLING_TOTAL_COUNT);
  },
);
