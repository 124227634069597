export default {
    
    GLOBAL_ALERT                               : 'globalAlert',

    CURRENT_USER                               : 'current_user',
    AUTHENTICATION                             : 'authentication',
    LOGIN_STATUS                               : 'login_status',
    
    



    /********************************************************************/ 
    ROUTER_LIST                                : 'router_list',

    UNPAGED_USER_LIST                          : 'unpaged_user_list',
    USER_LIST                                  : 'user_list',
    USER_TOTAL_COUNT                           : 'user_total_count',

    PAYMENT_ACCOUNT_LIST                       : 'payment_account_list',
    PAYMENT_ACCOUNT_TOTAL_COUNT                : 'payment_account_total_count',

    BILLING_LIST                               : 'billing_list',
    BILLING_TOTAL_COUNT                        : 'billing_total_count',

    PAYOUT_AMOUNT_GRAPH                        : 'payout_amount_graph',
    PAYOUT_AMOUNT_TOTAL                        : 'payout_amount_total',
    PAYMENT_ACCOUNT_NUMBER                     : 'payment_account_number',

    SUCCESS_PAYMENT_ACCOUNT_GRAPH              : 'success_payment_account_graph',
    SUCCESS_PAYMENT_ACCOOUNT_TOTAL             : 'success_payment_account_total',

    FAILED_PAYMENT_ACCOUNT_GRAPH               : 'failed_payment_account_graph',
    FAILED_PAYMENT_ACCOOUNT_TOTAL              : 'failed_payment_account_total',
  };
  

export const LOGIN_CONSTANTS = {
  LOGGED_IN                         : 'LOGGED_IN',
  LOGGED_OUT                        : 'LOGGED_OUT',
}

export const PAYMENT_TYPE_CONSTANTS = {
  STRIPE                         : 'Stripe',
  IBAN                           : 'Iban',
}