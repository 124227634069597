import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';
import PagePathConstants from '../constants/router/PagePathConstants';
import I18N from '../i18n';

const BASE_ROUTER_LIST = [
  {
    icon: 'fas fa-home',
    text: I18N.getText('dashboard-route'),
    path: PagePathConstants.DASHBOARD,
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.DASHBOARD) >= 0),
  },
  {
    icon: 'fas fa-dollar-sign',
    path: PagePathConstants.PAYMENT_ACCOUNT,
    text: I18N.getText('payment-account-route'),
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.PAYMENT_ACCOUNT) >= 0)
  },
  {
    icon: 'fas fa-wallet',
    path: PagePathConstants.WALLET,
    text: I18N.getText('wallet-route'),
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.WALLET) >= 0)
  },
  {
    icon: 'fas fa-hand-holding-usd',
    path: PagePathConstants.PAYOUT,
    text: I18N.getText('payout-route'),
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.PAYOUT) >= 0)
  },
  {
    icon: 'fas fa-file-invoice-dollar',
    path: PagePathConstants.BILL,
    text: I18N.getText('bill-route'),
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.BILL) >= 0)
  },
];


const ADMIN_ROUTER_LIST = [
  {
    icon: 'fas fa-user',
    path: PagePathConstants.USER,
    text: I18N.getText('user-route'),
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.USER) >= 0)
  }, {
    icon: 'fas fa-file-invoice-dollar',
    path: PagePathConstants.SETTLE_ACCOUNT,
    text: I18N.getText('settlement-route'),
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.SETTLE_ACCOUNT) >= 0)
  }, {
    icon: 'fas fa-money-bill',
    path: PagePathConstants.RECHARGE,
    text: I18N.getText('recharge-route'),
    isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.RECHARGE) >= 0)
  }, 
];

const initialState = Map({
  [StoreStateConstants.ROUTER_LIST]: BASE_ROUTER_LIST,
});

export const RouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_ROUTER_LIST: {
      return state.set(StoreStateConstants.ROUTER_LIST, action.payload);
    }
    case ActionConstants.SHOW_ADMIN_ROUTER: {
      // const WITHDRAW_ROUTER_LIST = [{
      //   icon: 'fas fa-hand-holding-usd',
      //   path: PagePathConstants.WITHDRAW_PAGE,
      //   text: I18N.getText('wallet-route'),
      //   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.WITHDRAW_PAGE) >= 0),
      //   children: [{
      //     icon: null,
      //     text: 'Withdraw List',
      //     path: PagePathConstants.WITHDRAW_PAGE
      //   }]
      // }];
      // return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST.concat(WITHDRAW_ROUTER_LIST).concat(ADMIN_ROUTER_LIST));
      return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST.concat(ADMIN_ROUTER_LIST));
    }
    case ActionConstants.SHOW_BASE_ROUTER: {
      return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST);
    }
    case ActionConstants.SHOW_FOREIGNER_ROUTER: {
      return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST.concat([]));
    }
    default:
      return state;
  }
};
