
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as billingActionCreator from '../../actions/billingActionCreator';
import * as paymentAccountActionCreator from '../../actions/paymentAccountActionCreator';

// --- Selectors --- //
import * as BillingSelectors from '../../selectors/BillingSelectors';
import * as UserSelectors from '../../selectors/UserSelectors';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';
import moment from 'moment';
import { PAYMENT_ACCOUNT_STATE_MAP } from '../../constants/BasicDataConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import LocalStorageConstants from '../../constants/LocalStorageConstants';
import UserHelper from '../../helpers/UserHelper';

class PayoutListPage extends Component {
  constructor(props, context) {
    super(props, context);
    const cachedData = LocalStorageUtil.loadSpecifiedPage(LocalStorageConstants.PAYOUT_QUERY_PARAMS);
    this.state = {
      payoutListParams: cachedData && cachedData !== 'undefined' && cachedData !== 'null' ? cachedData : {
        page: 1,
        per_page: 10,
        ZhangHaoID: '',
        ZhangDanZhongLei: 'payout'
      },
      paymentAccountList: [],
      selectPaymentAccount: null,
      showLoading: false,
      showDeleteConfirmModal: false,
      deleteItemId: '',
      typedPage: cachedData ? cachedData.page : 1,
    };

    this.getPayoutBillingList = this.getPayoutBillingList.bind(this);
    this.handlePaymentAccountFilterClick = this.handlePaymentAccountFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleViewPayoutRecord = this.handleViewPayoutRecord.bind(this);
    this.handleDeletePayoutRecord = this.handleDeletePayoutRecord.bind(this);
    this.createNewPayoutBill = this.createNewPayoutBill.bind(this);
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
    this.handleDeletePayoutItem = this.handleDeletePayoutItem.bind(this);
    this.handleAccountStateFilter = this.handleAccountStateFilter.bind(this);
    this.handleTypedPageChange = this.handleTypedPageChange.bind(this);
    this.watchKeyDown = this.watchKeyDown.bind(this);
    this.getUnpagedPaymentAccount = this.getUnpagedPaymentAccount.bind(this);
    this.renderBillType = this.renderBillType.bind(this);
  }

  componentDidMount() {
    this.getUnpagedPaymentAccount();
    this.getPayoutBillingList();
  }

  getUnpagedPaymentAccount() {
    // this.toggleLoading();
    const cachedData = LocalStorageUtil.loadSpecifiedPage(LocalStorageConstants.PAYOUT_QUERY_PARAMS);
    const { paymentAccountActions } = this.props;
    paymentAccountActions && paymentAccountActions.getUnpagedPaymentAccountList({ZhuangTai: 3})
      .then(data => {
        console.error(data);
        // this.toggleLoading();
        const formatPaymentAccountList = data && data.map(item => {
          return { value: item.id, label: item.ZhangHao, type: item.ZhangHaoZhongLei };
        });
        this.setState({
          paymentAccountList: formatPaymentAccountList,
          selectPaymentAccount: cachedData ? formatPaymentAccountList.filter(item => item.value === cachedData.ZhangHaoID)[0] : null,
        }, () => {
          console.error(this.state.paymentAccountList);
        });
      })
      .catch((err) => {
        console.error(err)
        // this.toggleLoading();
      });
  }

  handleTypedPageChange(e) {
    const typedPage = e && e.target.value;
    const regx = new RegExp(/^([1-9]|[1-9]\d+)?$/);
    if (regx.test(typedPage)) {
      this.setState({ typedPage });
    }
  }

  toggleDeleteConfirmModal() {
    this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
  }

  handleViewPayoutRecord(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.PAYOUT_EDIT.replace(':id', id));
  }

  handleDeletePayoutRecord(id) {
    this.toggleDeleteConfirmModal();
    this.setState({ deleteItemId: id });
  }

  handleDeletePayoutItem() {
    const { billingActions, billingListTotalNum, billingList } = this.props;
    let { deleteItemId, payoutListParams, typedPage } = this.state;

    const maxPage = Math.ceil(billingListTotalNum / payoutListParams.per_page);
    if (payoutListParams.page === maxPage && billingList.length === 1) {
      if (payoutListParams.page > 1) {
        payoutListParams.page = payoutListParams.page - 1;
        typedPage = payoutListParams.page
      }
    }
    billingActions && (deleteItemId && deleteItemId !== '') && billingActions.deleteBillingInfoById(this.state.deleteItemId)
      .then(data => {
        this.toggleDeleteConfirmModal();
        LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYOUT_QUERY_PARAMS, payoutListParams);
        this.setState({ payoutListParams, typedPage }, () => {
          this.getPayoutBillingList(payoutListParams);
        });
        return;
      })
      .catch(this.toggleDeleteConfirmModal);
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  getPayoutBillingList(params) {
    if (!params) {
      params = this.state.payoutListParams;
    }
    const { billingActions } = this.props;
    this.toggleLoading();
    return billingActions && billingActions.getBillingList(params)
      .then(data => {
        this.toggleLoading();
        return;
      })
      .catch(this.toggleLoading);
  }

  handlePaymentAccountFilterClick() {
    let { payoutListParams, typedPage } = this.state;
    payoutListParams.page = 1;
    typedPage = 1;
    LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYOUT_QUERY_PARAMS, payoutListParams);
    this.setState({ payoutListParams, typedPage });
    this.getPayoutBillingList(payoutListParams);
  }

  handlePageChange(e, page) {
    let { payoutListParams, typedPage } = this.state;
    payoutListParams.page = page;
    typedPage = page;
    this.getPayoutBillingList(payoutListParams)
      .then(() => {
        LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYOUT_QUERY_PARAMS, payoutListParams);
        this.setState({ payoutListParams, typedPage });
      });
  }

  createNewPayoutBill() {
    const { history } = this.props;
    history && history.push(PagePathConstants.PAYOUT_ADD);
  }

  renderSTCardStatus(state) {
    switch (state) {
      case 1: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('on')}</span>
          {/* <SvgIcon name="check-icon" color={ColorConstants.status.succeed} /> */}
        </div>)
      }
      case 0:
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('off')}</span>
          {/* <SvgIcon name="x-icon" color={ColorConstants.status.failed} /> */}
        </div>)
      }
    }
  }

  renderPaymentAccountStatus(status) {
    switch (status) {
      case 0: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-unpaid-bg tw-text-status-unpaid-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-assignment')}</span>
        </div>)
      }
      case 1: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-settle-accounts-bg tw-text-status-settle-accounts-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-registration')}</span>
        </div>)
      }
      case 2: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-approval')}</span>
        </div>)
      }
      case 3: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('done')}</span>
        </div>)
      }
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-being-processed-bg tw-text-status-being-processed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-assignment')}</span>
        </div>)
      }
    }
  }

  handleAccountStateFilter(selectPaymentAccount) {
    let { payoutListParams } = this.state;
    if (selectPaymentAccount) {
      payoutListParams.ZhangHaoID = selectPaymentAccount.value;
    } else {
      payoutListParams.ZhangHaoID = '';
    }
    LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYOUT_QUERY_PARAMS, payoutListParams);
    this.setState({ payoutListParams, selectPaymentAccount }, () => {
      this.getPayoutBillingList(payoutListParams)
    });
  }

  watchKeyDown(e) {
    if (e && e.key === 'Enter') {
      const { billingListTotalNum } = this.props;
      let { typedPage, payoutListParams } = this.state;
      const maxPage = Math.ceil(billingListTotalNum / payoutListParams.per_page);
      if (typedPage > maxPage) {
        typedPage = maxPage
      }
      if (typedPage < 1) {
        typedPage = 1;
      }
      payoutListParams.page = parseInt(typedPage);
      LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYOUT_QUERY_PARAMS, payoutListParams);
      this.setState({ typedPage, payoutListParams }, () => {
        this.getPayoutBillingList();
      });
    }
  }

  renderBillType(type) {
    switch (type) {
      case 'payout': {
        return I18N.getText('payout');
      }
      case 'shenhe': {
        return I18N.getText('account-cost');
      }
      case 'jiesuan': {
        return I18N.getText('share-of-profits');
      }
      case 'chongzhi': {
        return I18N.getText('recharge');
      }
      case 'chukuan': {
        return I18N.getText('Profit arrears')
      }
    }
  }

  render() {
    const { billingList, billingListTotalNum, currentUser } = this.props;
    const { payoutListParams, selectPaymentAccount, showLoading, showDeleteConfirmModal, typedPage, paymentAccountList } = this.state;
    const total = Math.ceil(billingListTotalNum / payoutListParams.per_page);
    const isAdmin = UserHelper.isAdminUser(currentUser)
    const isCustomer = UserHelper.isCustomer(currentUser)
    return (<div className='tw-w-full tw-px-[30px]'>
      {showLoading && <Loading />}
      <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeletePayoutItem} sectionName={DialogConstants.PAYOUT} />
      <div className='tw-w-full tw-mt-[30px] tw-bg-white tw-px-[20px] tw-py-[20px] tw-rounded-[30px] tw-shadow-2xl'>
        <div className='tw-flex tw-justify-between tw-items-center tw-w-full hs-userlist-filter-container'>
          <div className='tw-flex tw-items-center'>
            <div className=''>
              <Select
                className="tw-w-[350px] tw-rounded-[30px] tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-common-selector-container"
                styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                value={selectPaymentAccount}
                placeholder={I18N.getText('account-selector-tip')}
                onChange={this.handleAccountStateFilter}
                name="accountStatus"
                isClearable={true}
                options={paymentAccountList}
              />
            </div>
          </div>
          {isCustomer && <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            <RDSButton className="tw-text-white" onClick={this.createNewPayoutBill}>Create</RDSButton>
          </div>}
        </div>

        {billingList && billingList.length > 0 && <React.Fragment>
          <div className='tw-w-full tw-mt-[30px]'>
            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-h-[50px] tw-shadow-xl tw-bg-brand-primary-dark tw-text-white'>
                <div className='tw-w-[10%]'>{I18N.getText('account-type')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('payment-account')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('payout-amount')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('bill-type')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('billing-user')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[12px]'>
                {billingList && billingList.map(record => {
                  return (<div className='tw-rounded-[30px] tw-h-[40px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[10px] tw-shadow-xl hs-table-row'>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.ZhangHaoZhongLei}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-font-bold hs-td-cell'>{record.ZhangHaoName}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell'>{`${record.ZhangDanJinE}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell'>{this.renderBillType(record.ZhangDanZhongLei)}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.YongHuName}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{moment(new Date(record.updateTime * 1000)).format('ll h:mm A')}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <SvgIcon onClick={() => this.handleViewPayoutRecord(record.id)} size={20} color={ColorConstants.brand.primary} name="eye-icon" />
                      {(isAdmin || isCustomer) && <SvgIcon onClick={() => this.handleDeletePayoutRecord(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" />}
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
        </React.Fragment>}
        {billingList && billingList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
        <div className='tw-w-full tw-flex tw-justify-center tw-mt-[20px]'>
          <Pagination shape="rounded" variant="outlined" count={total} page={payoutListParams.page} onChange={this.handlePageChange} color="primary" />
          <div className='tw-ml-[40px] tw-flex tw-items-center'>
            <span className='tw-text-[12px]'>{I18N.getText('go-to-page')}</span>
            <EDSInput className="tw-mx-[10px] tw-w-[80px] tw-text-center" value={typedPage} onChange={this.handleTypedPageChange} onKeyDown={this.watchKeyDown} />
            <span className='tw-text-[12px]'>{I18N.getText('page')}</span>
          </div>
        </div>

      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    billingList: BillingSelectors.selectBillingList(state),
    billingListTotalNum: BillingSelectors.selectBillingRecordTotalNum(state),
    currentUser: UserSelectors.selectCurrentUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActionCreator, dispatch),
    paymentAccountActions: bindActionCreators(paymentAccountActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutListPage);

