import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Input } from 'reactstrap';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import LocaleCalendar from '../../components/common/LocaleCalendar';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Selectors --- //
import * as UserSelectors from '../../selectors/UserSelectors';

// --- Actions --- //
import * as paymentAccountActionCreator from '../../actions/paymentAccountActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';
import * as imgActionCreator from '../../actions/imgActionCreator';
import * as billingActionCreator from '../../actions/billingActionCreator';

// --- Icons --- //
import AddIcon from '../../icons/AddIcon';
import XIcon from '../../icons/XIcon';
import RdsRemoveIcon from '../../icons/RdsRemoveIcon';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';


import I18N from '../../i18n';
import UserHelper from '../../helpers/UserHelper';
import { PAYMENT_ACCOUNT_STATE_MAP, PAYMENT_ACCOUNT_STATE_MAP_FOR_ADMIN, PAYMENT_ACCOUNT_STATE_MAP_FOR_FOREIGNER } from '../../constants/BasicDataConstants';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';
import { getMMDDFormattedDate } from '../../utils/DateTimeUtil';
import moment from 'moment';


class BillEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectPaymentAccount: null,
            paymentAccountList: [],
            billingAmount: '',
            note: '',
            billingImg: '',

            isStartDateCalendarOpen: false,
            selectedBillingDate: null,

            showError: false,
            showLoading: false,
            errMsg: '',
            errFiled: '',
            isEditing: false,
        };

        this.handleBackPage = this.handleBackPage.bind(this);
        this.getUnpagedPaymentAccount = this.getUnpagedPaymentAccount.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);

        this.handleFormPropertyChange = this.handleFormPropertyChange.bind(this);
        this.removeImg = this.removeImg.bind(this);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleMinDateChange = this.handleMinDateChange.bind(this);
        this.toggleStartDateCalendar = this.toggleStartDateCalendar.bind(this);
        this.handleRemoveMinDate = this.handleRemoveMinDate.bind(this);
        this.editAccountPayoutInfo = this.editAccountPayoutInfo.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.getPaymentAccountById = this.getPaymentAccountById.bind(this);
    }

    componentDidMount() {
        this.getUnpagedPaymentAccount();
        
    }

    getPaymentAccountById() {
        const { location, billingActions } = this.props;
        const billingId = location.pathname.split(`${PagePathConstants.PAYOUT}/`)[1];
        console.error(billingId)
        if (billingId && billingId !== 'add') {
            this.toggleLoading();
            billingActions.getBillingInfoById(billingId)
                .then(data => {
                    const filterSelectPaymentAccount = this.state.paymentAccountList.filter(item => item.value === data.ZhangHaoID);
                    this.setState({
                        selectPaymentAccount: filterSelectPaymentAccount.length > 0 ? filterSelectPaymentAccount[0] : this.state.selectPaymentAccount,
                        billingAmount: data.ZhangDanJinE ? data.ZhangDanJinE.replace('-','') : this.state.billingAmount,
                        note: data.ShuoMing || this.state.note,
                        billingImg: data.img ? BASE_URL + data.img : this.state.img,
                        selectedBillingDate: data.updateTime ? getMMDDFormattedDate(new Date(data.updateTime * 1000)) : this.state.selectedBillingDate
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }

    }

    handleFormPropertyChange(e, property) {
        const value = e && e.target.value;
        if (property === 'billingAmount') {
            const regx = new RegExp(/^([-+]?)([0-9]|[1-9]\d+)?(\.[0-9]{0,2})?$/);
            if (regx.test(value) || value === '') {
                this.setState({ [property]: value, showError: false });
            }
        } else {
            this.setState({ [property]: value, showError: false });
        }
    }


    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading }, () => {
            console.warn(this.state.showLoading + '!!!!!!')
        });
    }

    getUnpagedPaymentAccount() {
        // this.toggleLoading();
        const { paymentAccountActions } = this.props;
        paymentAccountActions && paymentAccountActions.getUnpagedPaymentAccountList()
            .then(data => {
                // this.toggleLoading();
                const formatPaymentAccountList = data && data.map(item => {
                    return { value: item.id, label: item.ZhangHao, type: item.ZhangHaoZhongLei };
                });
                this.setState({ paymentAccountList: formatPaymentAccountList }, this.getPaymentAccountById())
            })
            .catch(() => {
                // this.toggleLoading();
            });
    }

    handleAccountChange(selectPaymentAccount) {
        this.setState({ selectPaymentAccount, showError: false });
    }

    handleSelectFile(e) {
        e.preventDefault();
        e && e.stopPropagation();
        const { imgActions } = this.props;
        const _this = this;
        [...e.target.files].forEach((fileInfo, index) => {
            let reads = new FileReader();
            reads.readAsDataURL(fileInfo);
            reads.onload = function (event) {
                const imgUrl = this.result

                _this.toggleLoading();
                imgActions && imgActions.uploadImgToGetUrl(imgUrl)
                    .then(url => {
                        _this.toggleLoading();

                        _this.setState({ billingImg: url });

                        event.target.value = '';
                    })
                    .catch((err) => {
                        console.error(err);
                        _this.toggleLoading();
                        event.target.value = '';
                    });
            };

        });
    }

    removeImg(ev) {
        ev && ev.stopPropagation();
        this.setState({ billingImg: undefined, showError: false });
    }

    handleMinDateChange(date) {
        this.setState({
            selectedBillingDate: getMMDDFormattedDate(date),
        });
        this.toggleStartDateCalendar();
    }

    toggleStartDateCalendar() {
        this.setState({
            isStartDateCalendarOpen: !this.state.isStartDateCalendarOpen,
        });
    }

    handleRemoveMinDate(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        this.setState({
            selectedBillingDate: null,
        })
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }


    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    editAccountPayoutInfo() {
        let { selectPaymentAccount, selectedBillingDate, billingAmount, note, billingImg } = this.state;
        console.log(selectedBillingDate);
        const {currentUser, billingActions, history} = this.props;
        let params = {
            ZhangDanZhongLei: "payout",
            ZhangHaoID: selectPaymentAccount.value,
            ZhangDanJinE: billingAmount,
            ShuoMing: note.trim(),
            YongHuID: currentUser && currentUser.id,
            img: billingImg && billingImg.replace(BASE_URL, ''),
            updateTime: selectedBillingDate && moment(selectedBillingDate).valueOf() / 1000,
        };

        this.toggleEditing();
        billingActions && billingActions.createBillingInfo(params)
        .then(() => {
            this.toggleEditing();
            history && history.push(PagePathConstants.PAYOUT);

        })
        .catch(() => {
            this.toggleEditing();
        })
        

    }

    render() {
        const { showError, errMsg, isEditing, showLoading, selectPaymentAccount, paymentAccountList, billingAmount, note, billingImg, isStartDateCalendarOpen, selectedBillingDate } = this.state;
        const { location } = this.props;
        const selectBillingDatePlaceholder = I18N.getText('select-billing-time');
        const billingDateTextLabel = selectedBillingDate || selectBillingDatePlaceholder;
        const selectedBillingDateForCalendar = selectedBillingDate
            ? new Date(selectedBillingDate)
            : new Date()

        const disabledBtn = isEditing || !selectPaymentAccount || !selectedBillingDate || billingAmount.trim() === '';
        const payoutId = location.pathname.split(`${PagePathConstants.PAYOUT}/`)[1];
        const isEditPayoutRecord = (payoutId && payoutId !== 'add');

        return <div className='tw-w-full tw-px-[30px] tw-py-[30px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('account')}</div>
                        <Select
                            isDisabled={isEditPayoutRecord}
                            className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container", {'disabled': isEditPayoutRecord})}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectPaymentAccount}
                            placeholder={I18N.getText('account-selector-tip')}
                            onChange={this.handleAccountChange}
                            name="account"
                            options={paymentAccountList}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('billing-time')}</div>
                        <div className='tw-w-full tw-flex tw-items-center tw-relative' >
                            <div className='tw-w-full tw-h-[45px] ' onClick={this.toggleStartDateCalendar}>
                                <span className={classNames('tw-border-[1px] tw-border-border-gray tw-w-full tw-h-[45px] tw-flex tw-justify-between tw-items-center tw-rounded-[30px] tw-text-[14px] tw-px-[20px] tw-bg-white tw-shadow-xl hs-calendar', {'disabled': isEditPayoutRecord})}>
                                    {billingDateTextLabel}
                                    <XIcon color={ColorConstants.brand.primary} className="tw-w-[20px] tw-h-[20px]" size={20} onClick={this.handleRemoveMinDate} />
                                </span>
                            </div>
                            {isStartDateCalendarOpen && !isEditPayoutRecord && <LocaleCalendar
                                value={selectedBillingDateForCalendar}
                                onChange={this.handleMinDateChange}
                                maxDate={new Date()}
                                className="tw-mt-[20px] tw-absolute tw-left-0 tw-top-[40px] tw-bg-white tw-w-[424px] tw-px-[30px] tw-py-[30px]"
                            />}
                        </div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('billing-amount')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('billing-amount-tip')}
                            value={billingAmount}
                            type="text"
                            disabled={isEditPayoutRecord}
                            onChange={(e) => this.handleFormPropertyChange(e, 'billingAmount')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('note')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('note-tip')}
                            value={note}
                            type="text"
                            disabled={isEditPayoutRecord}
                            onChange={(e) => this.handleFormPropertyChange(e, 'note')}
                        />
                    </div>

                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('payout-img')}</div>
                        <div className='tw-w-full tw-flex tw-items-center' >
                            <div className='tw-mr-[20px] tw-w-auto tw-flex tw-justify-start tw-items-center ' >
                                <div className={classNames('tw-w-[150px] tw-h-[150px] tw-mb-[20px]  tw-bg-background-3 tw-rounded-[15px] tw-border-[2px] tw-border-dashed tw-border-body-text-2 tw-relative hs-payout-certificate', {'disabled': isEditPayoutRecord})}>
                                    <AddIcon size={40} className="tw-absolute tw-left-[50%] tw-top-[50%] tw-ml-[-20px] tw-mt-[-20px] tw-z-[3]" />
                                    {!isEditPayoutRecord && <Input className='tw-z-[2] tw-relative tw-w-full tw-h-full tw-opacity-0 ' type='file' onChange={this.handleSelectFile} accept="image/*" />}
                                    {billingImg && <div style={{ backgroundImage: `url(${billingImg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className='tw-w-full tw-h-full tw-bg-cover tw-rounded-[15px] tw-absolute tw-top-0 tw-left-0 tw-z-[4]' />}
                                    {billingImg && <RdsRemoveIcon className="tw-absolute tw-right-[-10px] tw-bottom-[-10px] tw-z-[5]" color='#ACACAC' onClick={this.removeImg} />}
                                </div>
                            </div>
                        </div>
                    </div>



                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    {!isEditPayoutRecord && <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={disabledBtn}
                            onClick={this.editAccountPayoutInfo}
                            className="tw-text-white" >{I18N.getText('create')}</RDSButton>
                    </div>}

                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        currentUser: UserSelectors.selectCurrentUser(state),
        unpagedForeignerList: UserSelectors.selectUnpagedForeignerList(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        paymentAccountActions: bindActionCreators(paymentAccountActionCreator, dispatch),
        userActions: bindActionCreators(userActionCreator, dispatch),
        imgActions: bindActionCreators(imgActionCreator, dispatch),
        billingActions: bindActionCreators(billingActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillEditPage);