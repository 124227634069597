const Endpoint = {
    LOGIN: '/yonghu/login',
    LOGOUT: '/yonghu/logout',
    TELEGRAM_CODE: '/yonghu/telms',
    
    ME: '/yonghu/HuoQuYongHu',

    UNPAGED_USER_LIST: '/yonghu/getYonghuListN',
    USER_LIST: '/yonghu/getYonghuList',
    EDIT_USER: '/yonghu/setYonghu',
    USER_DETAIL: '/yonghu/getYonghu',
    DELETE_USER: '/yonghu/delYonghu',
    
    PAYMENT_ACCOUNT_LIST: '/zhanghaoguanli/getZhanghaoList',
    UNPAGED_PAYMENT_ACCOUNT_LIST: '/zhanghaoguanli/getZhanghaoListN',
    EDIT_PAYMENT_ACCOUNT: '/zhanghaoguanli/setZhanghao',
    PAYMENT_ACCOUNT_DETAIL: '/zhanghaoguanli/getZhanghao',
    DELETE_PAYMENT_ACCOUNT: '/zhanghaoguanli/delZhanghao',

    UPLOAD: '/common/upload',

    BILLING_LIST: '/zhangdanguanli/getZhangdanList',
    CREATE_BILLING_INFO: '/zhangdanguanli/setZhangdan',
    DELETE_BILLING_INFO: '/zhangdanguanli/delZhangdan',
    BILLING_INFO_DETAIL: '/zhangdanguanli/getZhangdan',

    CREATE_SETTLEMENT_INFO: '/zhangdanguanli/JieSuan',

    CREATE_RECHARGE_INFO: '/zhangdanguanli/chongzhi',

    PAYOUT_GRAPH_DATA: '/baobiao/Trend',
    PAYMENT_ACCOUNT_NUMBER: '/baobiao/ZhangHaoTongJi',
    PAYMENT_ACCOUNT_GRAPH_DATA: '/baobiao/ZhangHaoTrend',
}
export default Endpoint;

export const BASE_URL = 'https://www.niggapay.com';