import ActionConstants from '../constants/actions/ActionConstants';

export const updateRouterListAction = (data) => ({
    type: ActionConstants.UPDATE_ROUTER_LIST,
    payload: data,
});

export const showAdminRouterAction = () => ({
    type: ActionConstants.SHOW_ADMIN_ROUTER,
    payload: null,
});

export const showForeignerRouterAction = () => ({
    type: ActionConstants.SHOW_FOREIGNER_ROUTER,
    payload: null,
});

export const showBaseRouterAction = () => ({
    type: ActionConstants.SHOW_BASE_ROUTER,
    payload: null,
});

export const toggleMenuStatus = (menu) => {
    return function (dispatch, getState) {
        dispatch(updateRouterListAction(menu));
    }
}

export const updateMenuStatus = (menu) => {
    return function (dispatch, getState) {
        dispatch(updateRouterListAction(menu));
    }
}