import ActionConstants from '../constants/actions/ActionConstants';
import * as BillingAPI from '../apis/BillingAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateBillingListAction = (billingList, total) => ({
    type: ActionConstants.UPDATE_BILLING_LIST,
    payload: { data: billingList, total: total },
});

export const getBillingList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BillingAPI.fetchBillingList(params)
                .then((res) => {
                    if (res.status === 200) {
                        const billingList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateBillingListAction(billingList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const getBillingInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BillingAPI.getBillingInfoById(id)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const createBillingInfo = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BillingAPI.createBillingInfo(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('create-payout-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('create-payout-info-failed'));
                    return reject(err)
                });
        });
    };
}

export const deleteBillingInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BillingAPI.deleteBillingInfoById(id)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('delete-billing-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('delete-billing-info-failed'));
                    return reject(err)
                });
        });
    };
}
