import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectPaymentAccountReducer = state => state.get(ReducerNameConstants.PAYMENT_ACCOUNT);

export const selectPaymentAccountList = createSelector(
  [selectPaymentAccountReducer],
  (paymentAccountReducer) => {
    return paymentAccountReducer && paymentAccountReducer.get(StoreStateConstants.PAYMENT_ACCOUNT_LIST);
  },
);


export const selectPaymentAccountRecordTotalNum = createSelector(
  [selectPaymentAccountReducer],
  (paymentAccountReducer) => {
    return paymentAccountReducer && paymentAccountReducer.get(StoreStateConstants.PAYMENT_ACCOUNT_TOTAL_COUNT);
  },
);
