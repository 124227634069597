
import ActionConstants from '../constants/actions/ActionConstants';
import * as GraphAPI from '../apis/GraphAPI';
import { showErrorGlobalAlert } from './globalAlertActionCreator';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import moment from 'moment';


export const updateSuccessfulOrderGraphAction = (data) => ({
    type: ActionConstants.UPDATE_SUCCESSFUL_ORDER_GRAPH,
    payload: data,
});

export const updateFailedOrderGraphAction = (data) => ({
    type: ActionConstants.UPDATE_FAILED_ORDER_GRAPH,
    payload: data,
});

export const updateWithdrawGraphAction = (data) => ({
    type: ActionConstants.UPDATE_WITHDRAW_AMOUNT_GRAPH,
    payload: data,
});

export const updateOrderNumGraphAction = (data) => ({
    type: ActionConstants.UPDATE_SUCCESSFUL_ORDER_NUM_GRAPH,
    payload: data,
});

export const updateOrderStatisticsDataAction = (data) => ({
    type: ActionConstants.UPDATE_ORDER_STATISTICS_DATA,
    payload: data,
});



export const fetchOrderAmountGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            params['type'] = 'order_amount';
            GraphAPI.fetchGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const amountArr = res.data.data.list;
                        if (params.status === 1) {
                            dispatch(updateSuccessfulOrderGraphAction({
                                data: amountArr,
                            }));
                        }
                        if (params.status === 4) {
                            dispatch(updateFailedOrderGraphAction({
                                data: amountArr,
                            }));
                        }
                        callback && callback();
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};

export const fetchWithdrawAmountGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            params['type'] = 'withdraw';
            GraphAPI.fetchGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const amountArr = res.data.data.list;
                        dispatch(updateWithdrawGraphAction({
                            data: amountArr,
                        }));
                        callback && callback();
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};

export const fetchOrderNumGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            params['type'] = 'order_num';
            GraphAPI.fetchGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const amountArr = res.data.data.list;
                        dispatch(updateOrderNumGraphAction({
                            data: amountArr,
                        }));
                        callback && callback();
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};





export const updatePaymentAccountNumber = (data) => ({
    type: ActionConstants.UPDATE_PAYMENT_ACCOUNT_NUMBER,
    payload: data,
});


export const updatePayoutGraphAction = (data) => ({
    type: ActionConstants.UPDATE_PAYOUT_GRAPH,
    payload: data,
});

export const updateSuccessfulPaymentAccountGraphAction = (data) => ({
    type: ActionConstants.UPDATE_SUCCESSFUL_PAYMENT_ACCOUNT_GRAPH,
    payload: data,
});

export const updateFailedPaymentAccountGraphAction = (data) => ({
    type: ActionConstants.UPDATE_FAILED_PAYMENT_ACCOUNT_GRAPH,
    payload: data,
});

export const fetchPayoutGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            GraphAPI.fetchPayoutGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const amountArr = res.data.data.trend;
                        dispatch(updatePayoutGraphAction({
                            data: amountArr,
                            total: res.data.data.total
                        }));
                        callback && callback();
                        return resolve('success');
                    }
                    return reject('err');
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                    return reject('err');
                });
        });
    };
};

export const fetchPaymentAccountNumber = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            GraphAPI.fetchPaymentAccountNumber()
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                       const accountNum = res.data.data;
                       dispatch(updatePaymentAccountNumber(accountNum));
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};

export const fetchSuccessfulPaymentAccountNumberGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            GraphAPI.fetchPaymentAccountNumberGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const successAccount = res.data.data.trendCG;
                        const successAccountTotal = res.data.data.totalCG;
                        dispatch(updateSuccessfulPaymentAccountGraphAction({
                            successAccount,
                            successAccountTotal,
                        }));
                        callback && callback();
                        return resolve('success');
                    }
                    return reject('err');
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                    return reject('err');
                });
        });
    };
};


export const fetchFailedPaymentAccountNumberGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            GraphAPI.fetchPaymentAccountNumberGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {

                        const failedAccount = res.data.data.trendSB;
                        const failedAccountTotal = res.data.data.totalSB;
                        dispatch(updateFailedPaymentAccountGraphAction({
                            failedAccount,
                            failedAccountTotal,
                        }));
                        callback && callback();
                        return resolve('success');
                    }
                    return reject('err');
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                    return reject('err');
                });
        });
    };
};