import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getPaymentAccountInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.PAYMENT_ACCOUNT_DETAIL}`, {id});
}

export const editPaymentAccountInfoById = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_PAYMENT_ACCOUNT}`, params);
}

export const deletePaymentAccountInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.DELETE_PAYMENT_ACCOUNT}`, {id: id});
}

export const fetchPaymentAccountList = (params) => {
    return api.post(
        `${ApiEndpointConstants.PAYMENT_ACCOUNT_LIST}`,
        params,
    );
}

export const fetchUnpagedPaymentAccountList = (params) => {
    return api.post(
        `${ApiEndpointConstants.UNPAGED_PAYMENT_ACCOUNT_LIST}`,
        params,
    );
}