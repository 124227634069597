import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Select from 'react-select';

// --- Components --- //
import SvgIcon from '../components/common/SvgIcon';
import Loading from '../components/common/LoadingIndicator';
import SmaillLoading from '../components/common/SmaillLoading';

// --- Constants --- //
import ColorConstants from '../constants/ColorConstants';

// --- Actions --- //
import * as dashboardActionCreator from '../actions/dashboardActionCreator';
import * as userActionCreator from '../actions/userActionCreator';
import * as paymentAccountActionCreator from '../actions/paymentAccountActionCreator';

// --- Icons --- //
import MoneyIcon from '../icons/MoneyIcon';
import OrderIcon from '../icons/OrderIcon';
import ShopMallIcon from '../icons/ShopMallIcon';
import DollarIcon from '../icons/DollarIcon';
import CalendarIcon from '../icons/CalendarIcon';
import UserIcon from '../icons/UserIcon';

// --- Selectors --- //
import * as DashboardSelectors from '../selectors/DashboardSelectors';
import * as UserSelectors from '../selectors/UserSelectors';

// --- Selectors --- //
import chartBg from '../images/chart.png';
import classNames from 'classnames';

import patternBg from '../images/pattern.png';
import I18N from '../i18n/index';
import { color } from 'echarts';
import UserHelper from '../helpers/UserHelper';

class DashboardPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedCustomer: null,
            selectedPaymentAccount: null,
            paymentAccountList: [],
            payoutQueryParams: {
                type: 'month',
                ZhangHaoID: '',
                YongHuID: '',
            },
            showPayoutChartLoading: false,


            selectedUserForSuccessfulAccount: null,
            showSuccessfulAccountChartLoading: false,
            successfulAccountNumberQueryParams: {
                type: 'month',
                usertype: '',
                userid: '',
            },

            selectedUserForFailedAccount: null,
            showFailedAccountChartLoading: false,
            failedAccountNumberQueryParams: {
                type: 'month',
                usertype: '',
                userid: '',
            },  

           
        };

        this.handleSelectQueryTime = this.handleSelectQueryTime.bind(this);

        this.renderPayoutAmountChart = this.renderPayoutAmountChart.bind(this);
        this.getPayoutGraphData = this.getPayoutGraphData.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handlePaymentAccountChange = this.handlePaymentAccountChange.bind(this);
        this.getUnpagedCustomerList = this.getUnpagedCustomerList.bind(this);
        this.getUnpagedPaymentAccount = this.getUnpagedPaymentAccount.bind(this);
        this.renderPaymentAccountNumChart = this.renderPaymentAccountNumChart.bind(this);
        this.toggleChartOrTableLoading = this.toggleChartOrTableLoading.bind(this);
        this.getPaymentAccountNumber = this.getPaymentAccountNumber.bind(this);
        this.getSuccessfulPaymentAccountGraphData = this.getSuccessfulPaymentAccountGraphData.bind(this);
        this.renderSuccessPaymentAccountChart = this.renderSuccessPaymentAccountChart.bind(this);
        this.getFailedPaymentAccountGraphData = this.getFailedPaymentAccountGraphData.bind(this);
        this.renderFailedPaymentAccountChart = this.renderFailedPaymentAccountChart.bind(this);
        this.handleUserChangeForFailedAccount = this.handleUserChangeForFailedAccount.bind(this);
        this.handleUserChangeForSuccessfulAccount = this.handleUserChangeForSuccessfulAccount.bind(this);
    }

    componentDidMount() {
        this.getUnpagedCustomerList(this.getUnpagedPaymentAccount);
        this.getPayoutGraphData();
        this.getSuccessfulPaymentAccountGraphData();
        this.getFailedPaymentAccountGraphData();
        this.getPaymentAccountNumber();
    }

    getUnpagedCustomerList(callback) {
        const { userActions } = this.props;
        userActions && userActions.fetchUnpagedUserList()
            .then(() => {
                callback && callback.call(this);
            })
            .catch((err) => console.error(err));
    }

    getUnpagedPaymentAccount(customerId) {
        const { paymentAccountActions } = this.props;
        const params = customerId ? { YongYouZheID: customerId } : null
        paymentAccountActions && paymentAccountActions.getUnpagedPaymentAccountList(params)
            .then(data => {
                const formatPaymentAccountList = data && data.map(item => {
                    return { value: item.id, label: item.ZhangHao, type: item.ZhangHaoZhongLei };
                });
                this.setState({ paymentAccountList: formatPaymentAccountList })
            })
            .catch(() => {
            });
    }

    getPayoutGraphData() {
        this.toggleChartOrTableLoading('showPayoutChartLoading');

        const { dashboardActions } = this.props;
        const { payoutQueryParams } = this.state;
        
        this.toggleChartOrTableLoading('showPayoutChartLoading');

        dashboardActions && dashboardActions.fetchPayoutGraphData(payoutQueryParams)
            .then(() => {
                
                this.toggleChartOrTableLoading('showPayoutChartLoading');

            })
            .catch(() => {
                
            });
    }

    getPaymentAccountNumber() {
        const { dashboardActions } = this.props;
        dashboardActions && dashboardActions.fetchPaymentAccountNumber()
    }

    getSuccessfulPaymentAccountGraphData() {
        const { dashboardActions } = this.props;
        const { successfulAccountNumberQueryParams } = this.state;
        this.toggleChartOrTableLoading('showSuccessfulAccountChartLoading');
        dashboardActions && dashboardActions.fetchSuccessfulPaymentAccountNumberGraphData(successfulAccountNumberQueryParams)
            .then(() => {
                this.toggleChartOrTableLoading('showSuccessfulAccountChartLoading');
            })
            .catch(() => {
                this.toggleChartOrTableLoading('showSuccessfulAccountChartLoading');
            });
    }

    getFailedPaymentAccountGraphData() {
        const { dashboardActions } = this.props;
        const { failedAccountNumberQueryParams } = this.state;
        this.toggleChartOrTableLoading('showFailedAccountChartLoading');
        dashboardActions && dashboardActions.fetchFailedPaymentAccountNumberGraphData(failedAccountNumberQueryParams)
            .then(() => {
                this.toggleChartOrTableLoading('showFailedAccountChartLoading');
            })
            .catch(() => {
                this.toggleChartOrTableLoading('showFailedAccountChartLoading');
            });
    }

    toggleChartOrTableLoading(property) {
        this.setState({ [property]: !this.state[property] });
    }

    handleCustomerChange(selectedCustomer) {
        let { payoutQueryParams } = this.state;
        payoutQueryParams.YongHuID = selectedCustomer ? selectedCustomer.value : '';
        this.setState({ selectedCustomer, selectedPaymentAccount: null, payoutQueryParams }, () => {
            this.getUnpagedPaymentAccount(selectedCustomer.value);
        });
    }

    handlePaymentAccountChange(selectedPaymentAccount) {
        let { payoutQueryParams } = this.state;
        payoutQueryParams.ZhangHaoID = selectedPaymentAccount ? selectedPaymentAccount.value : '';
        this.setState({ selectedPaymentAccount, payoutQueryParams }, this.getPayoutGraphData);
    }

    handleUserChangeForSuccessfulAccount(selectedUserForSuccessfulAccount) {
        let { successfulAccountNumberQueryParams } = this.state;
        successfulAccountNumberQueryParams.userid = selectedUserForSuccessfulAccount ? selectedUserForSuccessfulAccount.value : '';
        successfulAccountNumberQueryParams.usertype = selectedUserForSuccessfulAccount ? selectedUserForSuccessfulAccount.type : '';
        this.setState({ selectedUserForSuccessfulAccount,  successfulAccountNumberQueryParams }, this.getSuccessfulPaymentAccountGraphData);
    }

    handleUserChangeForFailedAccount(selectedUserForFailedAccount) {
        let { failedAccountNumberQueryParams } = this.state;
        failedAccountNumberQueryParams.userid = selectedUserForFailedAccount ? selectedUserForFailedAccount.value : '';
        failedAccountNumberQueryParams.usertype = selectedUserForFailedAccount ? selectedUserForFailedAccount.type : '';
        this.setState({ selectedUserForFailedAccount,  selectedUserForFailedAccount }, this.getFailedPaymentAccountGraphData);
    }

    


    handleSelectQueryTime(category, time) {
        let queryParams = Object.assign({}, this.state[category]);
        queryParams.type = time;
        this.setState({ [category]: queryParams }, () => {
            switch (category) {
                case 'payoutQueryParams': {
                    this.getPayoutGraphData();
                } break;
                case 'successfulAccountNumberQueryParams': {
                    this.getSuccessfulPaymentAccountGraphData();
                } break;
                case 'failedAccountNumberQueryParams': {
                    this.getFailedPaymentAccountGraphData();
                } break;
                default: break;
            }
        });
    }

    renderPayoutAmountChart() {
        const { payoutGraphData } = this.props;
        let xAxisData = [];
        let payoutGraphYAxisData = [];
        if (payoutGraphData) {
            xAxisData = payoutGraphData.map(item => item.time_format);
            payoutGraphYAxisData = payoutGraphData.map(item => item.ZhangDanJinE);
        }

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['Payout amount']
            },
            toolbox: {
                // show: true,
                orient: 'vertical',
                left: 'right',
                top: 'center',
                feature: {
                    // mark: { show: true },
                    // dataView: { show: true, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    // restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    // axisTick: { show: false },
                    data: xAxisData,
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            grid: {
                bottom: '10%'
            },
            series: [
                {
                    name: 'Payout amount',
                    type: 'bar',
                    barGap: 0,
                    // label: labelOption,
                    // emphasis: {
                    //     focus: 'series'
                    // },
                    data: payoutGraphYAxisData,
                    itemStyle: {
                        normal: {
                            color: '#2C6DE9'
                        }
                    }
                }
            ]
        }
        return (<ReactECharts option={option} />)
    }

    renderSuccessPaymentAccountChart() {
        const { successPaymentAccountGraphData } = this.props;
        let xAxisData = [];
        let accountGraphYAxisData = [];
        if (successPaymentAccountGraphData) {
            xAxisData = successPaymentAccountGraphData.map(item => item.time_format);
            accountGraphYAxisData = successPaymentAccountGraphData.map(item => item.ShuLiang);
        }

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['Successful account number']
            },
            toolbox: {
                // show: true,
                orient: 'vertical',
                left: 'right',
                top: 'center',
                feature: {
                    // mark: { show: true },
                    // dataView: { show: true, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    // restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: xAxisData,
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            grid: {
                bottom: '10%',
                containLabel: true
            },
            series: [
                {
                    name: 'Payment account number',
                    type: 'line',
                    barGap: 0,
                    data: accountGraphYAxisData,
                    itemStyle: {
                        normal: {
                            color: '#3992f4'
                        }
                    },
                    areaStyle: { normal: { color: '#3992f4'} },
                }
            ]
        }
        return (<ReactECharts option={option} />)
    }

    renderFailedPaymentAccountChart() {
        const { failedPaymentAccountGraphData } = this.props;
        let xAxisData = [];
        let accountGraphYAxisData = [];
        if (failedPaymentAccountGraphData) {
            xAxisData = failedPaymentAccountGraphData.map(item => item.time_format);
            accountGraphYAxisData = failedPaymentAccountGraphData.map(item => item.ShuLiang);
        }

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['Failed account number']
            },
            toolbox: {
                // show: true,
                orient: 'vertical',
                left: 'right',
                top: 'center',
                feature: {
                    // mark: { show: true },
                    // dataView: { show: true, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    // restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    // axisTick: { show: false },
                    data: xAxisData,
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            grid: {
                bottom: '10%'
            },
            series: [
                {
                    name: 'Payout amount',
                    type: 'line',
                    barGap: 0,
                    // label: labelOption,
                    // emphasis: {
                    //     focus: 'series'
                    // },
                    data: accountGraphYAxisData,
                    itemStyle: {
                        normal: {
                            color: '#f1536e'
                        }
                    }
                }
            ]
        }
        return (<ReactECharts option={option} />)
    }

    renderPaymentAccountNumChart() {
        const { paymentAccountNumber } = this.props;
        return (
            <ReactECharts
                className='tw-w-full tw-h-full'
                option={{
                    title: {
                        text: 'Payment account Chart',
                        // subtext: 'Fake Data',
                        left: 'center',
                        show: false
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        left: 'center',
                        top: '30',
                        data: [
                            'Successful account num',
                            'Failed account num',
                            'Other states',
                        ],
                        show: false
                    },
                    // toolbox: {
                    //     show: true,
                    //     orient: 'vertical',
                    //     feature: {
                    //         // mark: { show: true },
                    //         magicType: { show: true, type: ['line', 'bar', 'stack'] },
                    //         // restore: { show: true },
                    //         saveAsImage: { show: true }
                    //     }
                    // },
                    series: [

                        {
                            name: 'Payment account number',
                            top: '-30px',
                            type: 'pie',
                            radius: [80, 110],
                            center: ['50%', '50%'],
                            roseType: 'radius',
                            itemStyle: {
                                borderRadius: 5
                            },
                            label: {
                                show: false
                            },
                            emphasis: {
                                label: {
                                    show: true
                                }
                            },
                            data: [
                                { value: paymentAccountNumber && paymentAccountNumber.totalCG, name: 'Successful account num', itemStyle: { color: '#2C6DE9' } },
                                { value: paymentAccountNumber && paymentAccountNumber.totalSB, name: 'Failed account num', itemStyle: { color: '#f1536e' } },
                                { value: paymentAccountNumber && (paymentAccountNumber.total - paymentAccountNumber.totalCG - paymentAccountNumber.totalSB), name: 'Other states', itemStyle: { color: '#FFCF6D' } }
                            ]
                        },
                    ]
                }}
            />
        )
    }

    render() {
        const {  payoutQueryParams, successfulAccountNumberQueryParams, failedAccountNumberQueryParams } = this.state;
        const { selectedCustomer, selectedPaymentAccount, paymentAccountList, showPayoutChartLoading, showSuccessfulAccountChartLoading, showFailedAccountChartLoading, selectedUserForSuccessfulAccount, selectedUserForFailedAccount, } = this.state;
        const { unpagedCustomerList, unpagedUserList, totalPayoutAmount, totalSuccessPaymentAccount, totalFailedPaymentAccount, currentUser } = this.props;
        const isAdmin = UserHelper.isAdminUser(currentUser);
        
        return (
            <div className='tw-h-full tw-px-[20px] tw-py-[20px]'>
                <div className='tw-w-full'>
                    <div className='tw-text-[15px] tw-font-bold tw-text-body-text-primary'>{I18N.getText('welcome')}</div>
                    <div className='tw-text-[12px] tw-text-body-text-grey tw-mt-[2px]'>{I18N.getText('congratulations')}</div>
                </div>
                <div className='tw-h-[427px] tw-mt-[12px] tw-flex tw-items-start '>
                    <div className='tw-w-[75%] tw-pr-[15px]'>
                        <div className='tw-w-full tw-bg-white tw-rounded-[6px] tw-px-[20px] tw-py-[15px] tw-shadow-2xl tw-relative'>
                        {showPayoutChartLoading && <SmaillLoading />}
                            
                            <div className='tw-w-[50%] tw-flex tw-items-center'>
                                {isAdmin && <Select
                                    className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-dashboard-selector-container tw-mr-[20px]")}
                                    styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                                    value={selectedCustomer}
                                    placeholder={I18N.getText('customer')}
                                    onChange={this.handleCustomerChange}
                                    name="account"
                                    options={unpagedCustomerList}
                                />}
                                <Select
                                    className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-dashboard-selector-container")}
                                    styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                                    value={selectedPaymentAccount}
                                    placeholder={I18N.getText('payment-account')}
                                    onChange={this.handlePaymentAccountChange}
                                    name="account"
                                    options={paymentAccountList}
                                />
                            </div>
                            <div className='tw-flex tw-justify-between tw-items-center tw-mt-[10px]'>
                                <span className='tw-text-[16px] tw-font-bold'>Payout Amount <span className='tw-ml-[10px] tw-text-[12px]'>(Total: ${totalPayoutAmount})</span></span>
                                <div className=' tw-rounded-full tw-flex tw-items-center tw-text-[14px] tw-font-bold tw-bg-[#886cc01a]'>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': payoutQueryParams && payoutQueryParams.type === 'year' })} onClick={() => this.handleSelectQueryTime('payoutQueryParams', 'year')}>Year</div>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': payoutQueryParams && payoutQueryParams.type === 'month' })} onClick={() => this.handleSelectQueryTime('payoutQueryParams', 'month')}>Month</div>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': payoutQueryParams && payoutQueryParams.type === 'week' })} onClick={() => this.handleSelectQueryTime('payoutQueryParams', 'week')}>Week</div>
                                </div>
                            </div>
                            <div className='tw-mt-[10px]'>
                                {this.renderPayoutAmountChart()}
                            </div>
                        </div>
                    </div>
                    <div className='tw-w-[25%] tw-h-full tw-pl-[15px]'>
                        <div className='tw-w-full tw-h-full tw-bg-white tw-shadow-2xl tw-rounded-[6px] '>
                            <div className='tw-w-full tw-h-full tw-px-[20px] tw-py-[20px] tw-flex tw-flex-col tw-items-center '>

                                <div className='tw-text-[16px] tw-font-bold'> Payment account's Overview </div>
                                <div className='tw-text-[12px] tw-mt-[5px]'> Show the proportion of successful and failed payment accounts number.</div>
                                <div className='tw-h-[calc(100%-60px)] tw-mt-[20px] tw-flex tw-flex-col tw-justify-start tw-items-start tw-font-[400]'>
                                    <div className='tw-flex tw-items-center'>
                                        <div className='tw-w-[12px] tw-h-[12px] tw-rounded-full tw-bg-[#2C6DE9]'></div>
                                        <div className='tw-ml-[10px] tw-text-[12px] tw-'>Successful account number</div>
                                    </div>
                                    <div className='tw-flex tw-items-center tw-mt-[6px]'>
                                        <div className='tw-w-[12px] tw-h-[12px] tw-rounded-full tw-bg-[#f1536e]'></div>
                                        <div className='tw-ml-[10px] tw-text-[12px] tw-'>Failed account number</div>
                                    </div>
                                    <div className='tw-flex tw-items-center tw-mt-[6px]'>
                                        <div className='tw-w-[12px] tw-h-[12px] tw-rounded-full tw-bg-[#FFCF6D]'></div>
                                        <div className='tw-ml-[10px] tw-text-[12px] tw-'>other account number</div>
                                    </div>

                                </div>
                                <div className='tw-w-full tw-h-full'>{this.renderPaymentAccountNumChart()}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='tw-mt-[20px] tw-w-full tw-flex tw-items-center tw-justify-between'>
                    <div className='tw-w-[49%]'>
                        <div className='tw-w-full tw-bg-white tw-rounded-[6px] tw-px-[20px] tw-py-[15px] tw-shadow-2xl tw-relative'>
                            {showSuccessfulAccountChartLoading && <SmaillLoading />}
                            {isAdmin && <div className='tw-w-[30%] tw-flex tw-items-center'>
                                <Select
                                    className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-dashboard-selector-container")}
                                    styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                                    value={selectedUserForSuccessfulAccount}
                                    placeholder={I18N.getText('user')}
                                    onChange={this.handleUserChangeForSuccessfulAccount}
                                    name="account"
                                    options={unpagedUserList}
                                />
                            </div>}
                            <div className='tw-flex tw-justify-between tw-items-center tw-mt-[10px]'>
                                <span className='tw-text-[16px] tw-font-bold'>Successful account number <span className='tw-ml-[10px] tw-text-[12px]'>(Total: ${totalSuccessPaymentAccount})</span></span>
                                <div className=' tw-rounded-full tw-flex tw-items-center tw-text-[14px] tw-font-bold tw-bg-[#886cc01a]'>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': successfulAccountNumberQueryParams && successfulAccountNumberQueryParams.type === 'year' })} onClick={() => this.handleSelectQueryTime('successfulAccountNumberQueryParams', 'year')}>Year</div>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': successfulAccountNumberQueryParams && successfulAccountNumberQueryParams.type === 'month' })} onClick={() => this.handleSelectQueryTime('successfulAccountNumberQueryParams', 'month')}>Month</div>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': successfulAccountNumberQueryParams && successfulAccountNumberQueryParams.type === 'week' })} onClick={() => this.handleSelectQueryTime('successfulAccountNumberQueryParams', 'week')}>Week</div>
                                </div>
                            </div>
                            <div className='tw-mt-[10px]'>
                                {this.renderSuccessPaymentAccountChart()}
                            </div>
                        </div>
                    </div>

                    <div className='tw-w-[49%]'>
                        <div className='tw-w-full tw-bg-white tw-rounded-[6px] tw-px-[20px] tw-py-[15px] tw-shadow-2xl tw-relative'>
                            {showFailedAccountChartLoading && <SmaillLoading />}
                            {isAdmin && <div className='tw-w-[30%] tw-flex tw-items-center'>
                                <Select
                                    className={classNames("tw-w-full tw-rounded-[30px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-dashboard-selector-container")}
                                    styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                                    value={selectedUserForFailedAccount}
                                    placeholder={I18N.getText('user')}
                                    onChange={this.handleUserChangeForFailedAccount}
                                    name="account"
                                    options={unpagedUserList}
                                />
                            </div>}
                            <div className='tw-flex tw-justify-between tw-items-center tw-mt-[10px]'>
                                <span className='tw-text-[16px] tw-font-bold'>Failed account number <span className='tw-ml-[10px] tw-text-[12px]'>(Total: ${totalFailedPaymentAccount})</span></span>
                                <div className=' tw-rounded-full tw-flex tw-items-center tw-text-[14px] tw-font-bold tw-bg-[#886cc01a]'>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': failedAccountNumberQueryParams && failedAccountNumberQueryParams.type === 'year' })} onClick={() => this.handleSelectQueryTime('failedAccountNumberQueryParams', 'year')}>Year</div>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': failedAccountNumberQueryParams && failedAccountNumberQueryParams.type === 'month' })} onClick={() => this.handleSelectQueryTime('failedAccountNumberQueryParams', 'month')}>Month</div>
                                    <div className={classNames('tw-rounded-full tw-px-[15px] tw-py-[8px]', { 'tw-bg-white animate-active-tab': failedAccountNumberQueryParams && failedAccountNumberQueryParams.type === 'week' })} onClick={() => this.handleSelectQueryTime('failedAccountNumberQueryParams', 'week')}>Week</div>
                                </div>
                            </div>
                            <div className='tw-mt-[10px]'>
                                {this.renderFailedPaymentAccountChart()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

        payoutGraphData: DashboardSelectors.selectPayoutGraphData(state),
        totalPayoutAmount: DashboardSelectors.selectTotalPayoutAmount(state),

        successPaymentAccountGraphData: DashboardSelectors.selectSuccessPaymentAccountGraphData(state),
        totalSuccessPaymentAccount: DashboardSelectors.selectSuccessPaymentAccountNumber(state),

        failedPaymentAccountGraphData: DashboardSelectors.selectFailedPaymentAccountGraphData(state),
        totalFailedPaymentAccount: DashboardSelectors.selectFailedPaymentAccountNumber(state),


        paymentAccountNumber: DashboardSelectors.selectPaymentAccountNumber(state),
        unpagedCustomerList: UserSelectors.selectUnpagedCustomerList(state),
        unpagedUserList: UserSelectors.selectUnpagedUserList(state),

        currentUser: UserSelectors.selectCurrentUser(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dashboardActions: bindActionCreators(dashboardActionCreator, dispatch),
        userActions: bindActionCreators(userActionCreator, dispatch),
        paymentAccountActions: bindActionCreators(paymentAccountActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);

