const kv = {
  'back': 'Back',
  'login-form-title': 'Log in to your account',
  'continue': 'Continue',
  'email': 'Email',
  'password': 'Password',
  'mobile': 'Mobile',


  'home-route': 'Home',
  'payment-route': 'Payment',
  'order-route': 'Order',
  'wallet-route': 'Wallet',
  'payout-route': 'Payout',
  'bill-route': 'Bill',
  'website-route': 'Website',
  'agent-route': 'Agent',
  'document-route': 'Document',
  'user-route': 'User',
  'settlement-route': 'Settlement',
  'st-route': 'Stripe',
  'iban-route': 'Iban',
  'ach-route': 'ACH',
  'coin-route': 'Coin',

  'dashboard-overview': 'Overview',
  'total-amount': 'Total amount',
  'expenditure-per-customer': 'Amount spent per customer',
  'withdraw-amount': 'Withdraw amount',
  'successful-payment': 'Successful payment',
  'failed-order': 'Failed order',


  'weekly': 'Weekly',
  'monthly': 'Monthly',

  'mall-order-num': 'Mall Order Number',
  'payment-channel-comment': 'Payment Aisle Comment',
  'mall-order-url': 'Mall Order Website',
  'order-num': 'Order Number',
  'order-email': 'Order Email',
  'order-phone': 'Order Phone',
  'amount': 'Amount',
  'real-amount': 'Real Amount',
  'status': 'Status',
  'description': 'Description',
  'date': 'Date',
  'operation': 'Operation',
  'no-records': 'No records',

  'all': 'All Status',
  'succeeded': 'Succeed',
  'failed': 'Failed',
  'being-processed': 'Being Processed',
  'notification': 'notification',
  'notified': 'Notified',
  'settle-accounts': 'Settled',
  'timeout': 'Timeout',
  'unpaid': 'Non-payment',

  'select_min_date': 'Start date',
  'select_max_date': 'End date',
  'payment-account-placeholder': 'Enter account to filter records',
  'order-email-search': 'Enter email to filter records',
  'order-phone-search': 'Enter telephone number to filter records',

  'wallet-overview': 'Overview',
  'withdraw': 'Withdraw',
  'balance': 'balance',
  'withdrawal-amount': 'withdrawal amount',
  'withdrawable-amount-tip': 'withdrawable amount is <a>%s</a>',
  'withdraw-account-tip': 'Please enter your withdrawal account',
  'withdraw-capital-letter': 'WITHDRAW',
  'withdraw-overflow-error': 'Your withdrawable balance is %s',

  'account': 'Account',
  'short-account': 'Short Account',
  'company': 'Company',
  'role': 'Role',
  'point': 'Commission rate',
  'username-placeholder': 'Enter a username to filter the data',
  'keywords-placeholder': 'Enter keywords to filter the data',
  'search': 'Search',
  'role-placeholder': 'Please select a role',
  'foreigner': 'Foreigner',
  'agent': 'Agent',
  'customer': 'Customer',
  'customer-selector-tip': 'Please select a customer',
  'mall-payment-aisle': 'Payment Aisle',
  'mall-payment-comment': 'Aisle Note',
  'admin': 'Admin',
  'username': 'Username',
  'edit': 'Edit',
  'create': 'Create',
  'tgid': 'Telegram Id',
  'inValid-email': 'Invalid email address',
  'inValid-mobile': 'Invalid phone number',
  'inValid-point': 'Invalid point number, please enter an integer between 0 and 100',
  'inValid-password': 'Invalid password, password must be at least 6 characters and at most 19 characters',
  'inValid-tgid': 'Invalid Telegram id',
  'point-tip': 'Please enter an integer between 0 and 100',
  'tgid-tip': 'Please enter Telegram id',
  'email-tip': 'Please enter your email address',
  'phone-tip': 'Please enter your phone number',
  'username-tip': 'Please enter your username',
  'password-tip': 'Please enter your password (minimum 6, maximum 19)',
  
  'edit-profile-succeed': 'User information edited successfully',
  'edit-profile-failed': 'Failed to edit user information. Please try again later',
  'create-user-succeed': 'User creation succeeded',
  'create-user-failed': 'Failed to create user. Please try again later',

  'comment': 'Comment',
  'c-site-url': 'Website URL',
  'max-money': 'Max amount',
  'max-order': 'Max order num',
  'total-money': 'Total money',
  'order-count': 'Order num',
  'comment-placeholder': 'Enter comments to filter the data',
  'api-key': 'API Key',
  'comment-tip': 'Please enter a comment',
  'api-key-tip': 'Please enter the API key',
  'public-key': 'Public key',
  'public-key-tip': 'Please enter the public key',
  'callback-key': 'Callback key',
  'callback-key-tip': 'Please enter the callback key',
  'c-site-url-tip': 'Please enter the website URL',
  'max-money-tip': 'Please enter the maximum amount',
  'max-order-tip': 'Please enter the maximum order number',
  'on': 'On',
  'off': 'Off',
  'is-top': 'Top',
  'inValid-comment': 'Invalid comment (must not contain special characters)',
  'inValid-apiKey': 'Invalid api key (must not contain special characters)',
  'inValid-publicKey': 'Invalid public key (must not contain special characters)',
  'inValid-endpointSecret': 'Invalid callback key (must not contain special characters)',
  'inValid-cSiteUrl': 'Invalid c site rl',
  'edit-st-info-succeed': 'St card information edited successfully',
  'edit-st-info-failed': 'Failed to edit st card information. Please try again later',
  'create-st-info-succeed': 'St card creation succeeded',
  'create-st-info-failed': 'Failed to create st card. Please try again later',

  'delete-st-info-succeed': 'Delete St card information successfully',
  'delete-st-info-failed': 'Failed to delete st card information. Please try again later',

  'edit-iban-info-succeed': 'IBAN card information edited successfully',
  'edit-iban-info-failed': 'Failed to edit IBAN card information. Please try again later',
  'create-iban-info-succeed': 'IBAN card creation succeeded',
  'create-iban-info-failed': 'Failed to create IBAN card. Please try again later',


  'delete-ach-info-succeed': 'Delete ACH card information successfully',
  'delete-ach-info-failed': 'Failed to delte ACH card. Please try again later',


  'order-no': 'Order Number',
  'product': 'Product',
  'product-title': 'Product Title',
  'specification': 'Specification',
  'num': 'Num',
  'phone': 'Phone',
  'address': 'Address',
  'order-time': 'Order Time',
  'ad': 'AD',


  'qr-code': 'QR code',
  'curcrency': 'Currency',
  'currency': 'Currency',
  'tel-id': 'Telegram Id',
  'card-number-placeholder': 'Enter the card numbers to filter the data',
  'tel-tip': 'Please enter your telegram id',
  'iban-account-tip': 'Please enter your IBAN card number',
  'GBP': 'GBP',
  'EUR': 'EUR',
  'inValid-iban-card-number': 'Invalid IBAN card number (must not contain special characters)',
  'inValid-ach-card-number': 'Invalid ACH card number (must not contain special characters)',
  'inValid-account': 'Invalid IBAN card number (must not contain special characters)',
  'repeated-telId': 'Duplicate telegram Id!',
  'repeated-cardId': 'Duplicate IBAN card number!',

  'allow-withdraw-action-succeed':'Withdrawal successfully reviewed',
  'forbid-withdraw-action-succeed':'Withdrawal review failed',
  'withdraw-action-failed': 'Withdrawal status update failed, please try again later',
  
  
  'logout': 'Logout',
  'stripe': 'Stripe',
  'Iban': 'Iban',

  
  'login-error': 'Account or password error, please try again',
  'logout-error': 'Server internal error, please try again later',
  'dashboard-amount-error': 'Failed to obtain graph data. Please try again later',
  'payment-record-error': 'Failed to obtain payment data. Please try again later',
  'withdraw-record-error': 'Failed to obtain withdrawal record. Please try again later',
  'withdraw-money-error': 'Withdrawal failed, please try again later',
  'withdraw-record-succeed': 'Withdrawal success!',
  'order-record-error': 'Failed to obtain order data. Please try again later',
  'order-notify-succeed': 'Order notification successful',
  'order-notify-failed': 'Order notification failure',

  'order-payment-status-update-succeed': 'The payment status of the order was updated successfully',
  'order-payment-status-update-failed': "The order's payment status update failed, please try again later.",

  'config-update-succeed': 'The basic configuration is updated successfully',
  'edit-tag-succeed': 'Tag information edited successfully',
  'edit-tag-failed': 'Failed to edit tag information. Please try again later',
  'create-tag-info-succeed': 'Tag creation succeeded',
  'create-tag-info-failed': 'Failed to create tag. Please try again later',

  'edit-category-succeed': 'Category information edited successfully',
  'edit-category-failed': 'Failed to edit category information. Please try again later',
  'create-category-info-succeed': 'Category creation succeeded',
  'create-category-info-failed': 'Failed to create category. Please try again later',

  'edit-product-succeed': 'Product information edited successfully',
  'edit-product-failed': 'Failed to edit product information. Please try again later',

  'rich-text-large-title': 'Large Title',
  'rich-text-medium-title': 'Medium Title',
  'rich-text-regular-text': 'Regular Text',
  'add-link': 'Add link',
  'please-select-resource-title': 'Please add title',


  'delete-info-succeed': 'Delete successfully!',
  'delete-info-failed': 'Failed to delete item!',

  'delete-tag': 'Delete Tag',
  'are-you-sure-you-want-to-delete-your-tag': 'Are you sure you want to delete your product tag?',
  'delete-category': 'Delete Category',
  'are-you-sure-you-want-to-delete-your-category': 'Are you sure you want to delete your product category?',
  'delete-product': 'Delete Product',
  'are-you-sure-you-want-to-delete-your-product': 'Are you sure you want to delete your product?',

  'company-address-tip': 'Please enter the company address',

  'group-name': 'Group name',
  'group-desc': 'Group description',
  'group-name-tip': 'Please enter a group name',
  'group-desc-tip': 'Please enter the group description',
  'st-group': 'Stripe group',
  'st-group-placeholder': 'Please select a stripe group',
  'code-sent-successfully': 'The verification code is sent successfully.',

  'ach-account-tip': 'Please enter your bank card number',
  'ach-short-account-tip': 'Please enter your short bank account',
  'ach-company-tip': 'Please enter your bank company address',




  /***********************新翻译字符串**************************/ 
  'dashboard-route': 'Dashboard',
  'say-hi': 'Hi!',
  'user-route': 'User',
  'back': 'Back',
  'account': 'Account',
  'account-tip': 'Please enter your account',
  'account-selector-tip': 'Please select your payment account',
  'password': 'Password',
  'percentage': 'Percentage  %',
  'percentage-tip': 'Please enter the percentage',
  'role': 'Role',
  'role-tip': 'Please select a user role',
  'delete-user-succeed': 'Delete user successfully',
  'delete-user-failed': 'Failed to delete user',
  'delete-user': 'Delete User',
  'are-you-sure-you-want-to-delete-this-user': 'Are you sure you want to delete this user?',
  'payment-account-route': 'Payment account',
  'wallet-route': 'Wallet',

  'account-type': 'Account type',
  'wallet-address': 'Wallet address',
  'payment-account': 'Payment Account',
  'payment-password': 'Payment Password',
  '2fa-pin': '2fa',
  'VPS': 'VPS',
  'VPS-account': 'VPS Account',
  'worldpay': 'Worldpay',
  'registration-cost': 'Registration Cost',
  'customer-cost': 'Quotations to customers',
  'select-the-account-status': 'Select payment account status to filter',
  'go-to-page': 'Go to',
  'page': 'page',
  'assigned-to': 'Assigned to',
  'assigned-to-tip': 'Please select account assigner',

  'account-status': 'Account status',
  'account-status-tip': 'Please select account status',

  'awaiting-assignment': 'Awaiting assignment',
  'awaiting-registration': 'Awaiting registration',
  'awaiting-approval': 'Awaiting approval',
  'done': 'Done',


  'profits-tip': 'Review your financial gains!',
  'total-revenue': 'Total Revenue',
  'payment-account-num': 'Number of Accounts',
  'edit-avatar': 'Edit Avatar',
  'profit': 'Profit',

  'payout-amount': 'Payout amount',
  'payout-amount-tip': 'Please enter your payout amount',
  'note': "Registrant's Remarks",
  'note2': "Customer's Remarks",
  'note-tip': 'Please enter a note',
  'billing-time': 'Billing time',
  'payout-time': 'Payout time',
  'select-billing-time': 'Please select a payout time',
  'payout-img': 'Payout credentials',
  'create-payout-info-succeed': 'Succeeded in creating a payout bill',
  'create-payout-info-failed': 'Failed to create payout bill',
  'delete-billing-info-succeed': 'Delete Bill Successfully',
  'delete-billing-info-failed': 'Failed to delete bill',
  'billing-user': 'Billing Customer',
  'payout-amount': 'Payout amount',
  'payout': 'Payout',
  'account-cost': 'Account Cost',
  'bill-type': 'Bill type',
  'profit-arrears': 'Profit arrears',
  'select-the-account-owner': 'Select the account owner',
  'create-settlement-info-succeed': 'Succeeded in creating a settlement bill',
  'create-settlement-info-failed': 'Failed to create settlement bill',
  'settlement-amount': 'Settlement amount',
  'settlement-amount-tip': 'Please enter your settlement amount',
  'share-of-profits': 'Share of profits',
  'recharge': 'Recharge',
  'recharge-route': 'Recharge',
  'user-selector-tip': 'Select the user',
  'recharge-amount': 'Recharge amount',
  'create-recharge-info-succeed': 'User recharges successfully',
  'create-recharge-info-failed': 'User recharge failure',
  'upload-avatar': 'Upload avatar',

  'edit-payment-info-succeed': 'Payment account information edited successfully',
  'edit-payment-info-failed': 'Failed to edit payment account information. Please try again later',


  'delete-payment-info-succeed': 'Delete payment account successfully',
  'delete-payment-info-failed': 'Failed to delete payment account. Please try again later',
  
  'registrant': 'Registrant',
  'welcome': 'Welcome !',
  'congratulations': 'Congratulations.',
  'user': 'User',
  'email-password': 'Email password',
  'VPS-password': 'VPS password',
  'Bank-info': 'Bank info',
  'Bank-info-tip': 'Please enter the bank information',

};
export { kv };

