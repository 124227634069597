import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectUserReducer = state => state.get(ReducerNameConstants.USER);

export const selectLoginStatus = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.LOGIN_STATUS);
  },
);

export const selectCurrentUser = createSelector(
  [selectUserReducer],
  (userReducer) => {
    const currentUser = userReducer && userReducer.get(StoreStateConstants.CURRENT_USER)
    return (currentUser && currentUser.toJS()) || {};
  },
);


export const selectUserList = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.USER_LIST);
  },
);


export const selectUserRecordTotalNum = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.USER_TOTAL_COUNT);
  },
);

export const selectUnpagedForeignerList = createSelector(
  [selectUserReducer],
  (userReducer) => {
    let userList = userReducer && userReducer.get(StoreStateConstants.UNPAGED_USER_LIST);
    userList = userList && userList.filter(item => {
      return item.type === 2;
    });
    userList = userList && userList.map(item => {
      return { value: item.id, label: item.username };
    })
    return userList;
  },
);


export const selectUnpagedCustomerList = createSelector(
  [selectUserReducer],
  (userReducer) => {
    let userList = userReducer && userReducer.get(StoreStateConstants.UNPAGED_USER_LIST);
    userList = userList && userList.filter(item => {
      return item.type === 1;
    });
    userList = userList && userList.map(item => {
      return { value: item.id, label: item.username };
    })
    return userList;
  },
);


export const selectUnpagedUserList = createSelector(
  [selectUserReducer],
  (userReducer) => {
    let userList = userReducer && userReducer.get(StoreStateConstants.UNPAGED_USER_LIST);
    userList = userList && userList.map(item => {
      return { value: item.id, label: item.username, type: item.type };
    })
    return userList;
  },
);

