const PagePathConstants = {
  ROOT_PAGE                     : "/",
  LOGIN_PAGE                    : '/login',
  DASHBOARD                     : '/dashboard',

  USER                          : '/user',
  USER_ADD                      : '/user/add',
  USER_EDIT                     : '/user/:id',

  PAYMENT_ACCOUNT               : '/payment',
  PAYMENT_ACCOUNT_ADD           : '/payment/add',
  PAYMENT_ACCOUNT_EDIT          : '/payment/:id',

  SETTLE_ACCOUNT                : '/settle',
  SETTLE_ACCOUNT_ADD            : '/settle/add',
  SETTLE_BY_ACCOUNT             : '/settle/:id',

  WALLET                        : '/wallet',
  PAYOUT                        : '/payout',
  PAYOUT_ADD                    : '/payout/add',
  PAYOUT_EDIT                   : '/payout/:id',

  BILL                          : '/bill',
  BILL_EDIT                     : '/bill/:id',

  RECHARGE                      : '/recharge'

};

export default PagePathConstants;
