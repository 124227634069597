import React from 'react';
import Colors from '../constants/ColorConstants';

const AddIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="3405" width={size} height={size}  {...props}>
    <path d="M443.6957 0l136.6086 0 0 1024-136.6086 0 0-1024Z" fill={color} p-id="3406"></path><path d="M0 443.6957l1024 0 0 136.6086-1024 0 0-136.6086Z" fill={color} p-id="3407"></path>
  </svg>
);

export default AddIcon