export default {
  // 登录相关
  UPDATE_LOGIN_STATUS: 'UPDATE_LOGIN_STATUS',
  LOGOUT: 'LOGOUT',
  UPDATE_AUTHENTICATION: 'UPDATE_AUTHENTICATION',
  UPDATE_PAYMENT_TYPE: 'UPDATE_PAYMENT_TYPE',
  CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',


  // 已登录用户信息
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',

  // 账单列表
  UPDATE_BILLING_LIST: 'UPDATE_BILLING_LIST',

  
  SHOW_GLOBAL_ALERT: 'SHOW_GLOBAL_ALERT',


  // Router
  UPDATE_ROUTER_LIST: 'UPDATE_ROUTER_LIST',
  SHOW_ADMIN_ROUTER: 'SHOW_ADMIN_ROUTER',
  SHOW_FOREIGNER_ROUTER: 'SHOW_FOREIGNER_ROUTER',
  SHOW_BASE_ROUTER: 'SHOW_BASE_ROUTER',

  UPDATE_UNPAGED_USER_LIST: 'UPDATE_UNPAGED_USER_LIST',
  UPDATE_USER_LIST: 'UPDATE_USER_LIST',
  UPDATE_PAYMENT_ACCOUNT_LIST: 'UPDATE_PAYMENT_ACCOUNT_LIST',

  UPDATE_PAYOUT_GRAPH: 'UPDATE_PAYOUT_GRAPH',
  UPDATE_SUCCESSFUL_PAYMENT_ACCOUNT_GRAPH: 'UPDATE_SUCCESSFUL_PAYMENT_ACCOUNT_GRAPH',
  UPDATE_FAILED_PAYMENT_ACCOUNT_GRAPH: 'UPDATE_FAILED_PAYMENT_ACCOUNT_GRAPH',
  
  UPDATE_PAYMENT_ACCOUNT_NUMBER: 'UPDATE_PAYMENT_ACCOUNT_NUMBER',
}
