import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from '@material-ui/lab/Pagination';
import cookie from "react-cookies";

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import UserAvatarModal from '../../components/common/dialog/UserAvatarModal';

// --- Icons --- //
import EditIcon from '../../icons/EditIcon';
import bgImg from '../../images/bg.png';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as paymentAccountActionCreator from '../../actions/paymentAccountActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';

// --- Selectors --- //
import * as PaymentAccountSelectors from '../../selectors/PaymentAccountSelectors';
import * as UserSelectors from '../../selectors/UserSelectors';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';
import moment from 'moment';
import { PAYMENT_ACCOUNT_STATE_MAP } from '../../constants/BasicDataConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import LocalStorageConstants from '../../constants/LocalStorageConstants';
import { parsePhoneNumber } from 'awesome-phonenumber';
import UserHelper from '../../helpers/UserHelper';
import StoreStateConstants from '../../constants/store/StoreStateConstants';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';

class WalletPage extends Component {
    constructor(props, context) {
        super(props, context);
        const cachedData = LocalStorageUtil.loadSpecifiedPage(LocalStorageConstants.WALLET_ACCOUNT_QUERY_PARAMS);
        this.state = {
            paymentAccountParams: cachedData && cachedData !== 'undefined' && cachedData !== 'null' ? cachedData : {
                page: 1,
                per_page: 8,
            },
            showLoading: false,
            typedPage: cachedData ? cachedData.page : 1,
            showAvatarDialog: false,
            coinAddress: '',
        };

        this.toggleLoading = this.toggleLoading.bind(this);
        this.getPaymentAccountList = this.getPaymentAccountList.bind(this);
        this.handleTypedPageChange = this.handleTypedPageChange.bind(this);
        this.watchKeyDown = this.watchKeyDown.bind(this);
        this.handleEditPaymentAccount = this.handleEditPaymentAccount.bind(this);
        this.toggleAvatarDialog = this.toggleAvatarDialog.bind(this);
        this.handleUpdateUserAvatar = this.handleUpdateUserAvatar.bind(this);
        this.getAdminCoinAddress = this.getAdminCoinAddress.bind(this);
        this.handleChangeCoinAddress = this.handleChangeCoinAddress.bind(this);
        this.updateAdminCoinAddress = this.updateAdminCoinAddress.bind(this);
    }

    componentDidMount() {
        const localAuth = cookie.load(StoreStateConstants.AUTHENTICATION, { path: '/' });
        const { userActions } = this.props;
        localAuth && userActions && userActions.fetchMeProfile();

        this.getPaymentAccountList();
        this.getAdminCoinAddress();
    }

    getAdminCoinAddress() {
        const { userActions } = this.props;
        userActions && userActions.getAdminProfileById()
            .then(data => {
                console.log(data);
                this.setState({ coinAddress: data.dizhi });
            })
            .catch(err => console.error(err));
    }

    updateAdminCoinAddress() {
        const { userActions } = this.props;
        this.toggleLoading();
        userActions && userActions.editUserProfileById({ id: 1, dizhi: this.state.coinAddress.trim() })
            .then(this.toggleLoading)
            .catch(this.toggleLoading);
    }

    getPaymentAccountList(params) {
        if (!params) {
            params = this.state.paymentAccountParams;
        }
        const { paymentAccountActions } = this.props;
        this.toggleLoading();
        return paymentAccountActions && paymentAccountActions.getPaymentAccountList(params)
            .then(data => {
                this.toggleLoading();
                return;
            });
    }

    handleEditPaymentAccount(id) {
        const { history } = this.props;
        history && history.push(PagePathConstants.PAYMENT_ACCOUNT_EDIT.replace(':id', id));
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleTypedPageChange(e) {
        const typedPage = e && e.target.value;
        const regx = new RegExp(/^([1-9]|[1-9]\d+)?$/);
        if (regx.test(typedPage)) {
            this.setState({ typedPage });
        }
    }

    handleChangeCoinAddress(e) {
        const value = e && e.target.value;
        this.setState({ coinAddress: value });
    }

    watchKeyDown(e) {
        if (e && e.key === 'Enter') {
            const { paymentAccountTotalNum } = this.props;
            let { typedPage, paymentAccountParams } = this.state;
            const maxPage = Math.ceil(paymentAccountTotalNum / paymentAccountParams.per_page);
            if (typedPage > maxPage) {
                typedPage = maxPage
            }
            if (typedPage < 1) {
                typedPage = 1;
            }
            paymentAccountParams.page = parseInt(typedPage);
            LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.WALLET_ACCOUNT_QUERY_PARAMS, paymentAccountParams);
            this.setState({ typedPage, paymentAccountParams }, () => {
                this.getPaymentAccountList();
            });
        }
    }

    renderPaymentAccountStatus(status) {
        switch (status) {
            case 0: {
                return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-unpaid-bg tw-text-status-unpaid-text tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('awaiting-assignment')}</span>
                </div>)
            }
            case 1: {
                return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-settle-accounts-bg tw-text-status-settle-accounts-text tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('awaiting-registration')}</span>
                </div>)
            }
            case 2: {
                return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-approval-bg tw-text-status-approval-text tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('awaiting-approval')}</span>
                </div>)
            }
            case 3: {
                return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('done')}</span>
                </div>)
            }
            case 4: {
                return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('failed')}</span>
                </div>)
            }
            default: {
                return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-being-processed-bg tw-text-status-being-processed-text tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('awaiting-assignment')}</span>
                </div>)
            }
        }
    }

    toggleAvatarDialog() {
        this.setState({ showAvatarDialog: !this.state.showAvatarDialog });
    }

    handleUpdateUserAvatar(avatar) {
        const { userActions, currentUser } = this.props;
        const params = {
            id: currentUser.id,
            img: avatar && avatar.replace(BASE_URL, '')
        }
        this.toggleLoading();
        userActions && userActions.editUserProfileById(params)
            .then(() => {
                userActions.fetchMeProfile();
                this.toggleLoading();
                this.toggleAvatarDialog();
            })
            .catch(() => {
                this.toggleLoading();
                this.toggleAvatarDialog();
            })
    }

    render() {
        const { currentUser, paymentAccountList, paymentAccountTotalNum } = this.props;
        const { paymentAccountParams, typedPage, showAvatarDialog, showLoading, coinAddress } = this.state;
        const total = Math.ceil(paymentAccountTotalNum / paymentAccountParams.per_page);
        const isAdmin = UserHelper.isAdminUser(currentUser);

        return (<div className='tw-w-full tw-px-[30px] tw-py-[30px]'>
            {showLoading && <Loading />}
            {showAvatarDialog && <UserAvatarModal confirmAction={this.handleUpdateUserAvatar} toggle={this.toggleAvatarDialog} />}
            <div className='tw-flex tw-items-center tw-justify-between'>
                <div className='tw-w-[48%] tw-h-[156px] tw-px-[28px] tw-py-[20px] tw-flex tw-items-center tw-bg-white tw-shadow-2xl tw-rounded-[30px]' style={{ backgroundImage: `url(${bgImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                    <div className='tw-w-[100px] tw-h-[100px] tw-rounded-full tw-border tw-flex tw-items-center tw-justify-center tw-relative'>
                        <img src={currentUser && currentUser.img && (BASE_URL + currentUser.img)} alt='avatar' className='tw-w-[94px] tw-h-[94px] tw-rounded-full' />
                        <i onClick={this.toggleAvatarDialog} className="fas fa-pencil-alt tw-hidden specific-screen:tw-block tw-text-[28px] tw-text-body-text-grey tw-absolute tw-right-[0px] tw-bottom-[0px]"></i>
                    </div>
                    <div className='tw-ml-[26px] tw-w-[calc(100%-126px)] tw-flex tw-items-start tw-justify-between'>
                        <div>
                            <div className='tw-text-[15px] tw-font-bold'>{I18N.getText('say-hi')} {currentUser && currentUser.username}</div>
                            <div className='tw-mt-[6px] tw-text-body-text-grey tw-text-[13px]'>{I18N.getText('profits-tip')}</div>

                            <div className='tw-mt-[16px]'>
                                <div className='tw-w-full tw-flex tw-items-center tw-text-[16px] tw-font-[500]'>
                                    <div className='tw-w-[150px] tw-text-[20px] tw-font-bold'>$ {currentUser.ZhangHuYuE}</div>
                                    <div className='tw-w-[200px]'>{paymentAccountTotalNum}</div>
                                </div>
                                <div className='tw-w-full tw-flex tw-items-center tw-text-body-text-grey tw-mt-[6px]'>
                                    <div className='tw-w-[150px]'>{I18N.getText('total-revenue')}</div>
                                    <div className='tw-w-[200px]'>{I18N.getText('payment-account-num')}</div>
                                </div>
                            </div>
                        </div>
                        <div className='specific-screen:tw-hidden'>
                            <div className=' tw-px-[15px] tw-py-[8px] tw-flex tw-items-center tw-bg-[#e3eaef] tw-text-[#313a46] tw-shadow-min-xl tw-rounded-[4px]' onClick={this.toggleAvatarDialog}>
                                <EditIcon className="tw-mr-[6px]" size={18} />
                                <span>{I18N.getText('edit-avatar')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tw-w-[48%] tw-h-[156px] tw-px-[28px] tw-py-[20px] tw-flex tw-flex-col tw-justify-center tw-items-start tw-bg-white tw-shadow-2xl tw-rounded-[30px]' style={{ backgroundImage: `url(${bgImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                    <div className='tw-font-bold tw-mb-[15px]' >{I18N.getText('wallet-address')}</div>
                    {isAdmin && <div className='tw-w-full tw-flex tw-justify-between tw-items-center'>
                        <EDSInput className="tw-w-full tw-mr-[30px] tw-h-[50px]  tw-text-center tw-bg-transparent !tw-border-white" value={coinAddress} onChange={this.handleChangeCoinAddress} />
                        <RDSButton onClick={this.updateAdminCoinAddress} color='primary' className="tw-text-white">{I18N.getText('update')}</RDSButton>
                    </div>}
                    {!isAdmin && <div className=''>{coinAddress}</div>}
                </div>
            </div>

            <div className='tw-mt-[30px] tw-shadow-2xl tw-bg-white'>
                <div className='tw-w-full hs-users-table-container'>
                    <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-h-[50px] tw-shadow-xl tw-bg-brand-primary-dark tw-text-white'>
                        {/* <div className='tw-w-[12.5%]'>{I18N.getText('comment')}</div> */}
                        <div className='tw-w-[20%]'>{I18N.getText('account-type')}</div>
                        <div className='tw-w-[20%]'>{I18N.getText('payment-account')}</div>
                        <div className='tw-w-[20%]'>{I18N.getText('c-site-url')}</div>
                        <div className='tw-w-[20%]'>{I18N.getText('status')}</div>
                        <div className='tw-w-[10%]'>{I18N.getText('profit')}</div>
                        <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
                    </div>
                    <div className='tw-w-full tw-text-[12px]  tw-pb-[30px] '>
                        {paymentAccountList && paymentAccountList.map(record => {
                            return (<div className='tw-rounded-[30px] tw-h-[40px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[10px] tw-shadow-xl hs-table-row'>
                                <div className='tw-w-[20%] tw-flex tw-justify-center tw-items-center'>
                                    <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.ZhangHaoZhongLei}</span>
                                </div>
                                <div className='tw-w-[20%] tw-flex tw-justify-center tw-items-center'>
                                    <span className='tw-font-bold hs-td-cell'>{record.ZhangHao}</span>
                                </div>
                                <div className='tw-w-[20%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.WangZhan}`}</div>
                                <div className='tw-w-[20%] tw-flex tw-justify-center tw-items-center'>{this.renderPaymentAccountStatus(record.ZhuangTai)}</div>
                                <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center hs-td-cell'>
                                    <i className="fas fa-coins tw-text-[16px] tw-mr-[6px] tw-text-status-settle-accounts-text"></i>
                                    {`${record.ZhangHuYuE}`}
                                </div>
                                <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                                    <SvgIcon onClick={() => this.handleEditPaymentAccount(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                                </div>
                            </div>)
                        })}
                        {paymentAccountList && paymentAccountList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
                            <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
                            <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
                        </div>}
                        {paymentAccountList && paymentAccountList.length > 0 && <div className='tw-w-full tw-flex tw-justify-center tw-mt-[20px]'>
                            <Pagination shape="rounded" variant="outlined" count={total} page={paymentAccountParams.page} onChange={this.handlePageChange} color="primary" />
                            <div className='tw-ml-[40px] tw-flex tw-items-center'>
                                <span className='tw-text-[12px]'>{I18N.getText('go-to-page')}</span>
                                <EDSInput className="tw-mx-[10px] tw-w-[80px] tw-text-center" value={typedPage} onChange={this.handleTypedPageChange} onKeyDown={this.watchKeyDown} />
                                <span className='tw-text-[12px]'>{I18N.getText('page')}</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        currentUser: UserSelectors.selectCurrentUser(state),
        paymentAccountList: PaymentAccountSelectors.selectPaymentAccountList(state),
        paymentAccountTotalNum: PaymentAccountSelectors.selectPaymentAccountRecordTotalNum(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        paymentAccountActions: bindActionCreators(paymentAccountActionCreator, dispatch),
        userActions: bindActionCreators(userActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);

