import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectDashboardReducer  = state => state.get(ReducerNameConstants.DASHBOARD);

// 成功订单金额图表数据
export const selectSuccessfulOrderGraphAmountList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const successfulOrderAmountGraphList = dashboardReducer && dashboardReducer.getIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.SUCCESSFUL_ORDER_GRAPH]);
    return successfulOrderAmountGraphList;
  },
);

// 失败订单金额图表数据
export const selectFailedOrderAmountGraphList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const failedOrderAmountGraphList = dashboardReducer && dashboardReducer.getIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.FIALED_ORDER_GRAPH]);
    return failedOrderAmountGraphList;
  },
);

// 提现图表数据
export const selectWithdrawGraphAmountList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const withdrawGraphList = dashboardReducer && dashboardReducer.get(StoreStateConstants.WITHDRAW_AMOUNT_GRAPH);
    return withdrawGraphList;
  },
);


// 成功订单数目图表数据
export const selectSuccessfulOrderNumGraphList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const successfulOrderNumGraphList = dashboardReducer && dashboardReducer.getIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.SUCCESSFUL_ORDER_NUM_GRAPH]);
    return successfulOrderNumGraphList;
  },
);


// 订单统计数据
export const selectOrderStatisticsData = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const orderStatisticsData = dashboardReducer && dashboardReducer.get(StoreStateConstants.ORDER_STATISTICS_DATA);
    return orderStatisticsData;
  },
);




// 出款数据
export const selectPayoutGraphData = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const payoutGraphData = dashboardReducer && dashboardReducer.get(StoreStateConstants.PAYOUT_AMOUNT_GRAPH);
    return payoutGraphData;
  },
);

// 出款数据
export const selectTotalPayoutAmount = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const totalPayoutAmount = dashboardReducer && dashboardReducer.get(StoreStateConstants.PAYOUT_AMOUNT_TOTAL);
    return totalPayoutAmount;
  },
);

// 账号数
export const selectPaymentAccountNumber = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const paymentAccountNumber = dashboardReducer && dashboardReducer.get(StoreStateConstants.PAYMENT_ACCOUNT_NUMBER);
    return paymentAccountNumber;
  },
);


// 成功账号图表
export const selectSuccessPaymentAccountGraphData = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const paymentAccountGraphData = dashboardReducer && dashboardReducer.get(StoreStateConstants.SUCCESS_PAYMENT_ACCOUNT_GRAPH);
    return paymentAccountGraphData;
  },
);

// 成功账号图表数目统计
export const selectSuccessPaymentAccountNumber = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const totalPaymentAccountNumber = dashboardReducer && dashboardReducer.get(StoreStateConstants.SUCCESS_PAYMENT_ACCOOUNT_TOTAL);
    return totalPaymentAccountNumber;
  },
);

// 失败账号图表
export const selectFailedPaymentAccountGraphData = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const paymentAccountGraphData = dashboardReducer && dashboardReducer.get(StoreStateConstants.FAILED_PAYMENT_ACCOUNT_GRAPH);
    return paymentAccountGraphData;
  },
);

// 成功账号图表数目统计
export const selectFailedPaymentAccountNumber = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const totalPaymentAccountNumber = dashboardReducer && dashboardReducer.get(StoreStateConstants.FAILED_PAYMENT_ACCOOUNT_TOTAL);
    return totalPaymentAccountNumber;
  },
);
