import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const fetchBillingList = (params) => {
    return api.post(
        `${ApiEndpointConstants.BILLING_LIST}`, params);
}

export const createBillingInfo = (params) => {
    return api.post(
        `${ApiEndpointConstants.CREATE_BILLING_INFO}`, params);
}

export const deleteBillingInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.DELETE_BILLING_INFO}`, {id: id});
}

export const getBillingInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.BILLING_INFO_DETAIL}`, {id});
}