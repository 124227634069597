import React from 'react';
import RDSButton from '../RDSButton';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DialogConstants from '../../../constants/dialog/index';
import I18N from '../../../i18n';


const DeleteConfirmationModal = ({
  isDeleteModalOpen, toggle, handleDeleteConfirm, sectionName,
}) => {
  let deletString = '';
  let deletFallbackString = '';
  let confirmString = '';
  let confirmFallbackString = '';
  switch (sectionName) {
    case DialogConstants.USER:
      deletString = 'delete-user';
      deletFallbackString = 'Delete User';
      confirmString = 'are-you-sure-you-want-to-delete-this-user';
      confirmFallbackString = 'Are you sure you want to delete this user?'
    break;
    case DialogConstants.PAYOUT:
      deletString = 'delete-payout';
      deletFallbackString = 'Delete payout record';
      confirmString = 'are-you-sure-you-want-to-delete-this-payout-record';
      confirmFallbackString = 'Are you sure you want to delete this payout record?'
    break;
    case DialogConstants.BILL:
      deletString = 'delete-bill';
      deletFallbackString = 'Delete bill record';
      confirmString = 'are-you-sure-you-want-to-delete-this-bill-record';
      confirmFallbackString = 'Are you sure you want to delete this bill record?'
    break;
    case DialogConstants.PAYMENT_ACCOUNT:
      deletString = 'delete-account';
      deletFallbackString = 'Delete Account';
      confirmString = 'are-you-sure-you-want-to-delete-this-account';
      confirmFallbackString = 'Are you sure you want to delete this payment account?'
    break;
    case DialogConstants.PRODUCT_CATEGORY:
      deletString = 'delete-category';
      deletFallbackString = 'Delete Category';
      confirmString = 'are-you-sure-you-want-to-delete-your-category';
      confirmFallbackString = 'Are you sure you want to delete your product category?'
    break;
    case DialogConstants.PRODUCT:
      deletString = 'delete-product';
      deletFallbackString = 'Delete Product';
      confirmString = 'are-you-sure-you-want-to-delete-your-product';
      confirmFallbackString = 'Are you sure you want to delete your product?'
    break;
    case DialogConstants.ST_CARD:
      deletString = 'delete-stcard';
      deletFallbackString = 'Delete ST Card';
      confirmString = 'are-you-sure-you-want-to-delete-your-stripe-card';
      confirmFallbackString = 'Are you sure you want to delete your stripe card?'
    break;
    case DialogConstants.ACH_CARD:
      deletString = 'delete-acgcard';
      deletFallbackString = 'Delete ACH Card';
      confirmString = 'are-you-sure-you-want-to-delete-your-ach-card';
      confirmFallbackString = 'Are you sure you want to delete your ACH card?'
    break;
    case DialogConstants.COIN_CARD:
      deletString = 'delete-coin-account';
      deletFallbackString = 'Delete the Coin Account';
      confirmString = 'are-you-sure-you-want-to-delete-your-coin-account';
      confirmFallbackString = 'Are you sure you want to delete your Coin account?'
    break;
    case DialogConstants.EDIT_ORDER_STATUS:
      deletString = 'edit-order-status';
      deletFallbackString = 'Modify order status';
      confirmString = 'are-you-sure-you-want-to-change-the-order-status-to-paid';
      confirmFallbackString = 'Are you sure you want to change the order status to paid?'
    break;
    default:
    break;
  }

  return (
    <div>
      <Modal isOpen={isDeleteModalOpen} toggle={toggle} className="profile-delete-modal-container">
        <ModalHeader toggle={toggle} >
          {I18N.getText(deletString, deletFallbackString)}
        </ModalHeader>
        <ModalBody className="profile-delete-modal-body">
          {I18N.getText(confirmString, confirmFallbackString)}
        </ModalBody>
        <ModalFooter>
          <RDSButton
            color="secondary"
            className="tw-text-brand-primary"
            onClick={toggle}
          >
            {I18N.getText('cancel', 'Cancel')}
          </RDSButton>
          <RDSButton
            className="tw-text-white"
            onClick={handleDeleteConfirm}
          >
            {I18N.getText('profile,yes', 'Yes')}
          </RDSButton>
        </ModalFooter>
      </Modal>
    </div>
  );
  
}
export default DeleteConfirmationModal;
