import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as paymentAccountActionCreator from '../../actions/paymentAccountActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';

// --- Selectors --- //
import * as PaymentAccountSelectors from '../../selectors/PaymentAccountSelectors';
import * as UserSelectors from '../../selectors/UserSelectors';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';
import moment from 'moment';
import { PAYMENT_ACCOUNT_STATE_MAP } from '../../constants/BasicDataConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import LocalStorageConstants from '../../constants/LocalStorageConstants';
import { parsePhoneNumber } from 'awesome-phonenumber';
import UserHelper from '../../helpers/UserHelper';

class WorldpayPage extends Component {
  constructor(props, context) {
    super(props, context);
    const cachedData = LocalStorageUtil.loadSpecifiedPage(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS);
    this.state = {
      paymentAccountParams: cachedData && cachedData !== 'undefined' && cachedData !== 'null' ? cachedData : {
        page: 1,
        per_page: 10,
        keywords: '',
        ZhuangTai: '',
        YongYouZheID: ''
      },
      selectedOwner: null,
      selectPaymentAccountStatus: cachedData ? PAYMENT_ACCOUNT_STATE_MAP.filter(item => item.value === cachedData.ZhuangTai)[0] : null,
      showLoading: false,
      showDeleteConfirmModal: false,
      deleteItemId: '',
      typedPage: cachedData ? cachedData.page : 1,
    };

    this.getPaymentAccountList = this.getPaymentAccountList.bind(this);
    this.handlePaymentAccountFilterClick = this.handlePaymentAccountFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleEditPaymentAccount = this.handleEditPaymentAccount.bind(this);
    this.handleDeletePaymentAccount = this.handleDeletePaymentAccount.bind(this);
    this.createNewPaymentAccount = this.createNewPaymentAccount.bind(this);
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.handleAccountStateFilter = this.handleAccountStateFilter.bind(this);
    this.handleTypedPageChange = this.handleTypedPageChange.bind(this);
    this.watchKeyDown = this.watchKeyDown.bind(this);
    this.getUnpagedCustomerList = this.getUnpagedCustomerList.bind(this);
    this.handleAccountOwnerFilter = this.handleAccountOwnerFilter.bind(this);
    this.routeToSettlePage = this.routeToSettlePage.bind(this);
  }

  componentDidMount() {
    this.getPaymentAccountList();
    this.getUnpagedCustomerList();
  }

  getUnpagedCustomerList() {
    const { userActions } = this.props;
    userActions && userActions.fetchUnpagedUserList()
      .then(() => {
      })
      .catch((err) => console.error(err));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { unpagedCustomerList } = nextProps;
    if (unpagedCustomerList.length  !== this.props.unpagedCustomerList) {
      const cachedData = LocalStorageUtil.loadSpecifiedPage(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS);
      if (cachedData) {
        const selectedOwner = unpagedCustomerList.filter(item => item.value === cachedData.YongYouZheID);
        if (selectedOwner.length > 0) {
          this.setState({ selectedOwner: selectedOwner[0] });
        }
      }
    }
  }

  handleTypedPageChange(e) {
    const typedPage = e && e.target.value;
    const regx = new RegExp(/^([1-9]|[1-9]\d+)?$/);
    if (regx.test(typedPage)) {
      this.setState({ typedPage });
    }
  }

  toggleDeleteConfirmModal() {
    this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
  }

  handleEditPaymentAccount(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.PAYMENT_ACCOUNT_EDIT.replace(':id', id));
  }

  routeToSettlePage(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.SETTLE_BY_ACCOUNT.replace(':id', id));
  }

  handleDeletePaymentAccount(id) {
    this.toggleDeleteConfirmModal();
    this.setState({ deleteItemId: id });
  }

  handleDeleteAccount() {
    const { paymentAccountActions, paymentAccountTotalNum, paymentAccountList } = this.props;
    let { deleteItemId, paymentAccountParams, typedPage } = this.state;

    const maxPage = Math.ceil(paymentAccountTotalNum / paymentAccountParams.per_page);
    if (paymentAccountParams.page === maxPage && paymentAccountList.length === 1) {
      if (paymentAccountParams.page > 1) {
        paymentAccountParams.page = paymentAccountParams.page - 1;
        typedPage = paymentAccountParams.page
      }
    }
    paymentAccountActions && (deleteItemId && deleteItemId !== '') && paymentAccountActions.deletePaymentAccountInfoById(this.state.deleteItemId)
      .then(data => {
        this.toggleDeleteConfirmModal();
        LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS, paymentAccountParams);
        this.setState({ paymentAccountParams, typedPage }, () => {
          this.getPaymentAccountList(paymentAccountParams);
        });
        return;
      })
      .catch(this.toggleDeleteConfirmModal);
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  getPaymentAccountList(params) {
    if (!params) {
      params = this.state.paymentAccountParams;
    }
    const { paymentAccountActions } = this.props;
    this.toggleLoading();
    return paymentAccountActions && paymentAccountActions.getPaymentAccountList(params)
      .then(data => {
        this.toggleLoading();
        return;
      });
  }

  handlePaymentAccountFilterClick() {
    let { paymentAccountParams, typedPage } = this.state;
    paymentAccountParams.page = 1;
    typedPage = 1;
    LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS, paymentAccountParams);
    this.setState({ paymentAccountParams, typedPage });
    this.getPaymentAccountList(paymentAccountParams);
  }

  handlePageChange(e, page) {
    let { paymentAccountParams, typedPage } = this.state;
    paymentAccountParams.page = page;
    typedPage = page;
    this.getPaymentAccountList(paymentAccountParams)
      .then(() => {
        LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS, paymentAccountParams);
        this.setState({ paymentAccountParams, typedPage });
      });
  }

  createNewPaymentAccount() {
    const { history } = this.props;
    history && history.push(PagePathConstants.PAYMENT_ACCOUNT_ADD);
  }

  renderSTCardStatus(state) {
    switch (state) {
      case 1: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('on')}</span>
          {/* <SvgIcon name="check-icon" color={ColorConstants.status.succeed} /> */}
        </div>)
      }
      case 0:
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('off')}</span>
          {/* <SvgIcon name="x-icon" color={ColorConstants.status.failed} /> */}
        </div>)
      }
    }
  }

  renderPaymentAccountStatus(status) {
    switch (status) {
      case 0: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-unpaid-bg tw-text-status-unpaid-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-assignment')}</span>
        </div>)
      }
      case 1: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-settle-accounts-bg tw-text-status-settle-accounts-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-registration')}</span>
        </div>)
      }
      case 2: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-approval-bg tw-text-status-approval-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-approval')}</span>
        </div>)
      }
      case 3: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('done')}</span>
        </div>)
      }
      case 4: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('failed')}</span>
        </div>)
      }
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[160px] tw-h-[32px] tw-text-[12px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-being-processed-bg tw-text-status-being-processed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('awaiting-assignment')}</span>
        </div>)
      }
    }
  }

  handleAccountStateFilter(selectPaymentAccountStatus) {
    let { paymentAccountParams } = this.state;
    if (selectPaymentAccountStatus) {
      paymentAccountParams.ZhuangTai = selectPaymentAccountStatus.value;
    } else {
      paymentAccountParams.ZhuangTai = '';
    }
    LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS, paymentAccountParams);
    this.setState({ paymentAccountParams, selectPaymentAccountStatus }, () => {
      this.getPaymentAccountList(paymentAccountParams)
    });
  }

  handleAccountOwnerFilter(selectedOwner) {
    let { paymentAccountParams } = this.state;
    if (selectedOwner) {
      paymentAccountParams.YongYouZheID = selectedOwner.value;
    } else {
      paymentAccountParams.YongYouZheID = '';
    }
    LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS, paymentAccountParams);
    this.setState({ paymentAccountParams, selectedOwner }, () => {
      this.getPaymentAccountList(paymentAccountParams)
    });
  }

  watchKeyDown(e) {
    if (e && e.key === 'Enter') {
      const { paymentAccountTotalNum } = this.props;
      let { typedPage, paymentAccountParams } = this.state;
      const maxPage = Math.ceil(paymentAccountTotalNum / paymentAccountParams.per_page);
      if (typedPage > maxPage) {
        typedPage = maxPage
      }
      if (typedPage < 1) {
        typedPage = 1;
      }
      paymentAccountParams.page = parseInt(typedPage);
      LocalStorageUtil.saveSpecifiedQueryParams(LocalStorageConstants.PAYMENT_ACCOUNT_QUERY_PARAMS, paymentAccountParams);
      this.setState({ typedPage, paymentAccountParams }, () => {
        this.getPaymentAccountList();
      });
    }
  }

  render() {
    const { paymentAccountList, paymentAccountTotalNum, currentUser, unpagedCustomerList } = this.props;
    const { paymentAccountParams, selectPaymentAccountStatus, selectedOwner, showLoading, showDeleteConfirmModal, typedPage } = this.state;
    const total = Math.ceil(paymentAccountTotalNum / paymentAccountParams.per_page);
    const isAdmin = UserHelper.isAdminUser(currentUser)
    const isCustomer = UserHelper.isCustomer(currentUser)
    const isForeigner = UserHelper.isForeigner(currentUser)
    return (<div className='tw-w-full tw-px-[30px]'>
      {showLoading && <Loading />}
      <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteAccount} sectionName={DialogConstants.PAYMENT_ACCOUNT} />
      <div className='tw-w-full tw-mt-[30px] tw-bg-white tw-px-[20px] tw-py-[20px] tw-rounded-[30px] tw-shadow-2xl'>
        <div className='tw-flex tw-justify-between tw-items-center tw-w-full hs-userlist-filter-container'>
          <div className='tw-flex tw-items-center'>
            {isAdmin && <div className=''>
              <Select
                className="tw-w-[350px] tw-rounded-[30px] tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-common-selector-container"
                styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                value={selectedOwner}
                placeholder={I18N.getText('select-the-account-owner')}
                onChange={this.handleAccountOwnerFilter}
                name="owner"
                isClearable={true}
                options={unpagedCustomerList}
              />
            </div>}

            <div className='tw-ml-[30px]'>
              <Select
                className="tw-w-[350px] tw-rounded-[30px] tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-common-selector-container"
                styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                value={selectPaymentAccountStatus}
                placeholder={I18N.getText('select-the-account-status')}
                onChange={this.handleAccountStateFilter}
                name="accountStatus"
                isClearable={true}
                options={PAYMENT_ACCOUNT_STATE_MAP}
              />
            </div>
          </div>
          {!isForeigner && <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            {/* <RDSButton className="tw-text-white tw-mr-[30px]" onClick={this.handlePaymentAccountFilterClick}>{I18N.getText('search')}</RDSButton> */}
            <RDSButton className="tw-text-white" onClick={this.createNewPaymentAccount}>Create</RDSButton>
          </div>}
        </div>

        {paymentAccountList && paymentAccountList.length > 0 && <React.Fragment>
          <div className='tw-w-full tw-mt-[30px]'>

            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-h-[50px] tw-shadow-xl tw-bg-brand-primary-dark tw-text-white'>
                {/* <div className='tw-w-[12.5%]'>{I18N.getText('comment')}</div> */}
                <div className='tw-w-[10%]'>{I18N.getText('account-type')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('payment-account')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('payment-password')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('2fa-pin')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('VPS')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('c-site-url')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('status')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[12px]'>
                {paymentAccountList && paymentAccountList.map(record => {
                  return (<div className='tw-rounded-[30px] tw-h-[40px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[10px] tw-shadow-xl hs-table-row'>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.ZhangHaoZhongLei}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-font-bold hs-td-cell'>{record.ZhangHao || ''}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell'>{`${record.MiMa || ''}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.Pin || ''}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.VPS || ''}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.WangZhan || ''}`}</div>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>{this.renderPaymentAccountStatus(record.ZhuangTai)}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{moment(new Date(record.updateTime * 1000)).format('ll h:mm A')}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      {isAdmin && <SvgIcon onClick={() => this.routeToSettlePage(record.id)} size={20} color={ColorConstants.brand.primary} name="settle-icon" />}
                      <SvgIcon onClick={() => this.handleEditPaymentAccount(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" className="tw-ml-[3px]" />
                      {isAdmin && <SvgIcon onClick={() => this.handleDeletePaymentAccount(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" />}
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
        </React.Fragment>}
        {paymentAccountList && paymentAccountList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
        <div className='tw-w-full tw-flex tw-justify-center tw-mt-[20px]'>
            <Pagination shape="rounded" variant="outlined" count={total} page={paymentAccountParams.page} onChange={this.handlePageChange} color="primary" />
            <div className='tw-ml-[40px] tw-flex tw-items-center'>
              <span className='tw-text-[12px]'>{I18N.getText('go-to-page')}</span>
              <EDSInput className="tw-mx-[10px] tw-w-[80px] tw-text-center" value={typedPage} onChange={this.handleTypedPageChange} onKeyDown={this.watchKeyDown} />
              <span className='tw-text-[12px]'>{I18N.getText('page')}</span>
            </div>
          </div>
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    paymentAccountList: PaymentAccountSelectors.selectPaymentAccountList(state),
    paymentAccountTotalNum: PaymentAccountSelectors.selectPaymentAccountRecordTotalNum(state),
    currentUser: UserSelectors.selectCurrentUser(state),
    unpagedCustomerList: UserSelectors.selectUnpagedCustomerList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paymentAccountActions: bindActionCreators(paymentAccountActionCreator, dispatch),
    userActions: bindActionCreators(userActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorldpayPage);

