import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Selectors --- //
import * as UserSelectors from '../../selectors/UserSelectors';

// --- Actions --- //
import * as paymentAccountActionCreator from '../../actions/paymentAccountActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';


import I18N from '../../i18n';
import UserHelper from '../../helpers/UserHelper';
import { PAYMENT_ACCOUNT_STATE_MAP, PAYMENT_ACCOUNT_STATE_MAP_FOR_ADMIN, PAYMENT_ACCOUNT_STATE_MAP_FOR_FOREIGNER } from '../../constants/BasicDataConstants';

const ACCOUNT_TYPE_LIST = [
    { value: 'worldpay', label: I18N.getText('worldpay') },
];

class PaymentAccountEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectAccountType: ACCOUNT_TYPE_LIST[0],
            account: '',
            accountPassword: '',
            pin: '',
            VPS: '',
            VPSAccount: '',
            VPSPassword: '',
            BankInfo: '',
            email: '',
            emailPassword: '',
            cSiteUrl: '',
            note: '',
            note2: '',
            registrationCost: '',
            customerCost: '',
            status: '',
            selectedOwner: null,
            selectedRegistrantAssigner: null,
            selectedAccountStatus: null,

            showError: false,
            showLoading: false,
            errMsg: '',
            errFiled: '',
            isEditing: false,
        };

        this.handleFormPropertyChange = this.handleFormPropertyChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.editPaymentAccountInfo = this.editPaymentAccountInfo.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.getAssignerList = this.getAssignerList.bind(this);
        this.handleAssignerChange = this.handleAssignerChange.bind(this);
        this.handleOwnerChange = this.handleOwnerChange.bind(this);
        this.handleAccountStatusChange = this.handleAccountStatusChange.bind(this);
    }

    componentDidMount() {
        const {currentUser} = this.props;
        const isAdmin = UserHelper.isAdminUser(currentUser);
        if (isAdmin) {
            this.getAssignerList(this.getPaymentAccountById);
        } else {
            this.getPaymentAccountById();
        }
        
    }

    getAssignerList(callback) {
        const { userActions } = this.props;
        userActions && userActions.fetchUnpagedUserList()
        .then(() => {
            callback && callback.call(this);
        })
        .catch((err) => console.error(err));
    }

    getPaymentAccountById() {
        const { location, paymentAccountActions, unpagedForeignerList, unpagedCustomerList } = this.props;
        const paymentAccountId = location.pathname.split(`${PagePathConstants.PAYMENT_ACCOUNT}/`)[1];
        if (paymentAccountId && paymentAccountId !== 'add') {
            this.toggleLoading();
            paymentAccountActions.getPaymentAccountInfoById(paymentAccountId)
                .then(data => {
                    const filterSelectType = ACCOUNT_TYPE_LIST.filter(item => item.value === data.ZhangHaoZhongLei);
                    const filterSelectAssigner = unpagedForeignerList.filter(item => item.value === data.ZhuCeZheID);
                    const filterSelectOwner = unpagedCustomerList.filter(item => item.value === data.YongYouZheID);
                    const filterSelectAccountStatus = PAYMENT_ACCOUNT_STATE_MAP.filter(item => item.value === data.ZhuangTai);

                    console.log(unpagedCustomerList);
                    console.log(filterSelectOwner);
                    
                    this.setState({
                        selectAccountType: filterSelectType.length > 0 ? filterSelectType[0] : this.state.selectAccountType,
                        account: data.ZhangHao || this.state.account,
                        accountPassword: data.MiMa || this.state.accountPassword,
                        pin: data.Pin || this.state.pin,
                        VPS: data.VPS || this.state.VPS,
                        VPSAccount: data.VpsZhangHao || this.state.VPSAccount,
                        VPSPassword: data.VpsMiMa || this.state.VPSPassword,
                        BankInfo: data.Bankinfo || this.state.BankInfo,
                        email: data.YouXiang || this.state.email,
                        emailPassword: data.YouXiangMiMa || this.state.emailPassword,
                        cSiteUrl: data.WangZhan || this.state.cSiteUrl,
                        note: data.BeiZhu || this.state.note,
                        note2: data.note2 || this.state.note2,
                        registrationCost: data.ZhuCeZheChengBen || this.state.registrationCost,
                        customerCost: data.YongYouZheChengBen || this.state.customerCost,
                        status: data.ZhuangTai !== undefined || data.ZhuangTai !== null ? data.ZhuangTai : this.state.status,
                        selectedRegistrantAssigner: filterSelectAssigner.length > 0 ? filterSelectAssigner[0] : this.state.selectedRegistrantAssigner,
                        selectedAccountStatus: filterSelectAccountStatus.length > 0 ? filterSelectAccountStatus[0] : this.state.selectedAccountStatus,
                        selectedOwner: filterSelectOwner.length > 0 ? filterSelectOwner[0] : this.state.selectedOwner,
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }

    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleFormPropertyChange(e, property) {
        const value = e && e.target.value;
        if (property === 'registrationCost' || property === 'customerCost') {
            const regx = new RegExp(/^([-+]?)([0-9]|[1-9]\d+)?(\.[0-9]{0,2})?$/);
            if (regx.test(value) || value === '') {
                this.setState({ [property]: value, showError: false });
            }
        } else {
            this.setState({ [property]: value, showError: false });
        }
    }

    handleAccountTypeChange(accountType) {
        this.setState({ selectAccountType: accountType, showError: false });
    }

    handleAssignerChange(selectedRegistrantAssigner) {
        this.setState({ selectedRegistrantAssigner, showError: false });
    }

    handleOwnerChange(selectedOwner) {
        this.setState({ selectedOwner, showError: false });
    }

    handleAccountStatusChange(selectedAccountStatus) {
        this.setState({ selectedAccountStatus, showError: false });
    }

    editPaymentAccountInfo() {
        const { paymentAccountActions, history, location, currentUser } = this.props;
        const paymentAccountId = location.pathname.split(`${PagePathConstants.PAYMENT_ACCOUNT}/`)[1];
        let { selectAccountType, account, accountPassword, pin, VPS, VPSAccount, VPSPassword, BankInfo, email, emailPassword, cSiteUrl, note, note2, registrationCost, customerCost, selectedRegistrantAssigner, selectedAccountStatus, selectedOwner } = this.state;
        if (selectedAccountStatus && (selectedAccountStatus.value === 2 || selectedAccountStatus.value === 3)) {

        }
        if (email.trim() !== '' && !FormValidationsUtil.isValidEmail(email.trim())) {
            this.setState({ showError: true, errFiled: 'email', errMsg: I18N.getText('inValid-email') });
            return;
        }
        let params = {
            ZhangHaoZhongLei: selectAccountType.value,
            ZhangHao: account,
            MiMa: accountPassword,
            Pin: pin,
            VPS,
            VpsZhangHao: VPSAccount,
            VpsMiMa: VPSPassword,
            YouXiang: email,
            YouXiangMiMa: emailPassword,
            WangZhan: cSiteUrl,
            BeiZhu: note,
            note2: note2,
            ZhuangTai: selectedAccountStatus ? selectedAccountStatus.value : 0
        }
        const isAdmin = UserHelper.isAdminUser(currentUser);
        const isCustomer = UserHelper.isCustomer(currentUser);
        const isForeigner = UserHelper.isForeigner(currentUser);
        if (isAdmin) {
            params['YongYouZheChengBen'] = customerCost;
            params['ZhuCeZheChengBen'] = registrationCost;
        }
        if (isAdmin && selectedRegistrantAssigner) {
            params['ZhuCeZheID'] = selectedRegistrantAssigner.value;
        }
        if (isAdmin && selectedOwner) {
            params['YongYouZheID'] = selectedOwner.value;
        }
        if ((isAdmin || isCustomer) && BankInfo.trim() !== '' ) {
            params['Bankinfo'] = BankInfo;
        }
        if (isCustomer) {
            params['YongYouZheID'] = currentUser.id;
        }
        if (paymentAccountId && paymentAccountId !== 'add') {
            params['id'] = paymentAccountId;
        }
        this.toggleEditing();
        paymentAccountActions && paymentAccountActions.editPaymentAccountInfoById(params)
            .then(() => {
                history && history.push(PagePathConstants.PAYMENT_ACCOUNT);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, errFiled, errMsg, showError, showLoading, status, selectAccountType, account, accountPassword, pin, VPS, VPSAccount, VPSPassword, BankInfo, email, emailPassword, cSiteUrl, note, note2, registrationCost, customerCost, selectedRegistrantAssigner, selectedOwner, selectedAccountStatus } = this.state;
        const { location, currentUser, unpagedForeignerList, unpagedCustomerList } = this.props;
        const paymentAccountId = location.pathname.split(`${PagePathConstants.PAYMENT_ACCOUNT}/`)[1];
        const isEditPaymentAccount = (paymentAccountId && paymentAccountId !== 'add');
        const isAdmin = UserHelper.isAdminUser(currentUser);
        const isForeigner = UserHelper.isForeigner(currentUser);
        const isCustomer = UserHelper.isCustomer(currentUser);
        let disabledBtn = isEditing || cSiteUrl.trim() === '' || email.trim() === '' || emailPassword.trim() === '';
        if (selectedAccountStatus && (selectedAccountStatus.value === 2 || selectedAccountStatus.value === 3)) {
            disabledBtn = disabledBtn || !selectAccountType || account.trim() === '' || accountPassword.trim() === '' || pin.trim() === '' || VPS.trim() === ''
            || VPSAccount.trim() === '' || VPSPassword.trim() === '' || !selectedRegistrantAssigner || !selectedOwner;
            if (selectedAccountStatus.value === 3) {
                disabledBtn = disabledBtn || registrationCost === '' || customerCost === '' || parseFloat(registrationCost) === 0 || parseFloat(customerCost) === 0;
            }
        }

        return <div className='tw-w-full tw-px-[30px] tw-py-[30px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('account-type')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectAccountType}
                            placeholder={I18N.getText('account-type')}
                            onChange={this.handleAccountTypeChange}
                            name="accountType"
                            options={ACCOUNT_TYPE_LIST}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('payment-account')}</div>
                        <EDSInput
                            disabled={isCustomer}
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('payment-account-tip')}
                            value={account || ""}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'account')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('payment-password')}</div>
                        <EDSInput
                            disabled={isCustomer}
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('payment-password-tip')}
                            value={accountPassword || ''}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'accountPassword')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('2fa-pin')}</div>
                        <EDSInput
                            disabled={isCustomer}
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('2fa-pin-tip')}
                            value={pin || ""}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'pin')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('VPS')}</div>
                        <EDSInput
                            disabled={isForeigner}
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('VPS-tip')}
                            value={VPS || ""}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'VPS')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('VPS-account')}</div>
                        <EDSInput
                            disabled={isForeigner}
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('VPSAccount-tip')}
                            value={VPSAccount}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'VPSAccount')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('VPS-password')}</div>
                        <EDSInput
                            disabled={isForeigner}
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('VPS-password-tip')}
                            value={VPSPassword}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'VPSPassword')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('Bank-info')}</div>
                        <EDSInput
                            disabled={isForeigner}
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('Bank-info-tip')}
                            value={BankInfo}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'BankInfo')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('email')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'email') })}
                            placeholder={I18N.getText('email-tip')}
                            value={email}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'email')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('email-password')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('email-password-tip')}
                            value={emailPassword}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'emailPassword')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('c-site-url')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('c-site-url-tip')}
                            value={cSiteUrl}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'cSiteUrl')}
                        />
                    </div>
                    
                    {isAdmin && <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('registration-cost')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('registration-cost-tip')}
                            value={registrationCost}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'registrationCost')}
                        />
                    </div>}
                    {isAdmin && <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('customer-cost')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('customer-cost-tip')}
                            value={customerCost}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'customerCost')}
                        />
                    </div>}
                    {(isForeigner || isAdmin) && <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('account-status')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedAccountStatus}
                            placeholder={I18N.getText('account-status-tip')}
                            onChange={this.handleAccountStatusChange}
                            name="assignedTo"
                            isOptionDisabled={(option) => option.disabled}
                            options={isAdmin ? PAYMENT_ACCOUNT_STATE_MAP_FOR_ADMIN : PAYMENT_ACCOUNT_STATE_MAP_FOR_FOREIGNER}
                        />
                    </div>}
                    {(isAdmin && unpagedCustomerList.length > 0) && <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('owner')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedOwner}
                            placeholder={I18N.getText('owner-to-tip')}
                            onChange={this.handleOwnerChange}
                            name="owner"
                            options={unpagedCustomerList}
                        />
                    </div>}
                    {/* 如果状态为0表示该账号没有指配老外 */}
                    {(isAdmin && unpagedForeignerList.length > 0) && <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('assigned-to')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedRegistrantAssigner}
                            placeholder={I18N.getText('assigned-to-tip')}
                            onChange={this.handleAssignerChange}
                            name="assignedTo"
                            options={unpagedForeignerList}
                        />
                    </div>}
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('note')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('note-tip')}
                            value={note}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'note')}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('note2')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-vibrant-purple focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('note-tip')}
                            value={note2}
                            type="text"
                            onChange={(e) => this.handleFormPropertyChange(e, 'note2')}
                        />
                    </div>
                    
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={disabledBtn}
                            onClick={this.editPaymentAccountInfo}
                            className="tw-text-white" >{isEditPaymentAccount ? I18N.getText('edit') : I18N.getText('create')}</RDSButton>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        currentUser: UserSelectors.selectCurrentUser(state),
        unpagedForeignerList: UserSelectors.selectUnpagedForeignerList(state),
        unpagedCustomerList: UserSelectors.selectUnpagedCustomerList(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        paymentAccountActions: bindActionCreators(paymentAccountActionCreator, dispatch),
        userActions: bindActionCreators(userActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAccountEditPage);