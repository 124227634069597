import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';


const initialState = Map({



  [StoreStateConstants.PAYOUT_AMOUNT_GRAPH]: null,
  [StoreStateConstants.PAYOUT_AMOUNT_TOTAL]: 0,

  [StoreStateConstants.SUCCESS_PAYMENT_ACCOUNT_GRAPH]: null,
  [StoreStateConstants.SUCCESS_PAYMENT_ACCOOUNT_TOTAL]: 0,

  [StoreStateConstants.FAILED_PAYMENT_ACCOUNT_GRAPH]: null,
  [StoreStateConstants.FAILED_PAYMENT_ACCOOUNT_TOTAL]: 0,

  [StoreStateConstants.PAYMENT_ACCOUNT_NUMBER]: null,
});

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_PAYOUT_GRAPH: {
      return state.set(StoreStateConstants.PAYOUT_AMOUNT_GRAPH, action.payload.data).set(StoreStateConstants.PAYOUT_AMOUNT_TOTAL, action.payload.total);
    }
    case ActionConstants.UPDATE_PAYMENT_ACCOUNT_NUMBER: {
      return state.set(StoreStateConstants.PAYMENT_ACCOUNT_NUMBER, action.payload);
    }
    case ActionConstants.UPDATE_SUCCESSFUL_PAYMENT_ACCOUNT_GRAPH: {
      return state.set(StoreStateConstants.SUCCESS_PAYMENT_ACCOUNT_GRAPH, action.payload.successAccount).set(StoreStateConstants.SUCCESS_PAYMENT_ACCOOUNT_TOTAL, action.payload.successAccountTotal);
    }
    case ActionConstants.UPDATE_FAILED_PAYMENT_ACCOUNT_GRAPH: {
      return state.set(StoreStateConstants.FAILED_PAYMENT_ACCOUNT_GRAPH, action.payload.failedAccount).set(StoreStateConstants.FAILED_PAYMENT_ACCOOUNT_TOTAL, action.payload.failedAccountTotal);
    }

    default:
      return state;
  }
};
