import React from "react";
import Colors from '../constants/ColorConstants';

const EditIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg width={size} height={size} viewBox={viewBox} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="m6.103 15.874 2.977-.271 5.6-5.605L11.984 7.3l-5.617 5.617-.264 2.956zm7.221-9.912 2.695 2.695 1.947-1.95-2.694-2.694-1.948 1.949zM4.293 17.686a1.003 1.003 0 0 1-.289-.798l.379-4.17c.042-.457.244-.888.57-1.214l8.996-8.996c.702-.705 1.975-.67 2.716.069l2.738 2.738h.001c.765.767.796 1.985.068 2.715l-8.997 8.997a1.975 1.975 0 0 1-1.214.569l-4.17.379a1.003 1.003 0 0 1-.798-.289zM20 20.978c0 .55-.45 1-1 1H5c-.549 0-1-.45-1-1s.451-1 1-1h14c.55 0 1 .45 1 1z" fill={color}/>
  </svg>
);
export default EditIcon;
