import React from 'react';
import Colors from '../constants/ColorConstants';

const ShopMallIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024"  p-id="5975" width={size} height={size} {...props}>
    <path d="M864 617.6c-19.2 0-32 12.8-32 32v195.2c0 9.6-9.6 19.2-19.2 19.2H211.2c-9.6 0-19.2-9.6-19.2-19.2v-195.2c0-19.2-12.8-32-32-32s-32 12.8-32 32v195.2C128 889.6 166.4 928 211.2 928h601.6c44.8 0 83.2-38.4 83.2-83.2v-195.2c0-19.2-12.8-32-32-32zM956.8 316.8l-105.6-204.8c-6.4-9.6-16-16-28.8-16h-640c-12.8 0-25.6 9.6-28.8 22.4l-83.2 208c-6.4 6.4-3.2 22.4-3.2 25.6v80c0 80 67.2 147.2 147.2 147.2H256c48 0 86.4-16 112-44.8 25.6 28.8 70.4 44.8 124.8 44.8h41.6c54.4 0 99.2-16 124.8-44.8 25.6 25.6 64 41.6 112 41.6h41.6c80 0 147.2-67.2 147.2-147.2v-83.2c0-3.2 0-22.4-3.2-28.8zM204.8 160h598.4l67.2 128H150.4l54.4-128z m608 352h-41.6c-19.2 0-83.2-3.2-83.2-64 0-19.2-12.8-32-32-32s-32 12.8-32 32v12.8c0 51.2-76.8 54.4-92.8 54.4h-41.6c-16 0-92.8-3.2-92.8-54.4v-6.4-3.2c0-19.2-12.8-32-32-32s-32 12.8-32 32c0 48-41.6 64-83.2 64H211.2c-44.8 0-83.2-38.4-83.2-83.2V352h768v76.8c0 44.8-35.2 83.2-83.2 83.2z" fill={color} p-id="5976"></path>
  </svg>
);

export default ShopMallIcon