import React, { Component } from 'react';
import {
    Dropdown, DropdownToggle,
    DropdownMenu, DropdownItem,
} from 'reactstrap';
import SvgIcon from '../SvgIcon';
import Loading from '../LoadingIndicator';
import I18N from '../../../i18n';
import PagePathConstants from '../../../constants/router/PagePathConstants';
import { BASE_URL } from '../../../constants/api/ApiEndpointConstants';
import ArrowRightIcon from '../../../icons/ArrowRightIcon';
import ColorConstants from '../../../constants/ColorConstants';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfileDropdown: false,
            showLoading: false
        }
        this.renderUserProfileDropdownList = this.renderUserProfileDropdownList.bind(this);
        this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this);
        this.logout = this.logout.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.updateMenuTitle = this.updateMenuTitle.bind(this);
    }

    updateMenuTitle(props) {
        let { navRouterList, location } = props || this.props;

        const activeNav = navRouterList.filter(nav => {
            return location.pathname.indexOf(nav.path) >= 0;
        });

        return activeNav && activeNav[0] && activeNav[0].text;
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    toggleProfileDropdown() {
        this.setState({ showProfileDropdown: !this.state.showProfileDropdown });
    }

    logout() {
        const { userActions, history } = this.props;
        this.toggleLoading();
        userActions && userActions.logout()
            .then(() => {
                this.toggleLoading();
                history && history.push(PagePathConstants.LOGIN_PAGE);
            });
    }

    renderUserProfileDropdownList() {
        const { currentUser } = this.props;
        const { showProfileDropdown } = this.state;
        return (<Dropdown className="hs-Entrancepage-profile tw-h-auto" isOpen={showProfileDropdown} toggle={this.toggleProfileDropdown} direction='down'>
            <DropdownToggle className="tw-rounded-[50px] tw-text-[16px] tw-text-body-text-1 !tw-border-none !tw-px-0 !tw-py-0">
                <div className='tw-w-[50px] tw-h-[50px] tw-rounded-full tw-overflow-hidden tw-flex tw-justify-center tw-items-center hs-user-avatar'>
                    <div className='tw-w-[44px] tw-h-[44px] tw-rounded-full tw-overflow-hidden tw-text-[12px]' style={{ backgroundImage: `url(${currentUser && currentUser.img && (BASE_URL + currentUser.img)})`, backgroundSize: '100%', backgroundPosition: 'center' }}></div>
                </div>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    className='tw-w-[200px] tw-h-[30px] tw-text-left tw-pl-[10px] tw-py-0 tw-text-[12px]'
                    onClick={this.logout}>
                    <div className='tw-flex tw-w-full tw-h-full tw-items-center tw-px-[10px] tw-border-none'>
                        <SvgIcon name="logout-icon" />
                        <span className='tw-ml-[20px] tw-text-body-text-1'>{I18N.getText('logout')}</span>
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>)
    }


    render() {
        const { showLoading } = this.state;
        const activeMenuTitle = this.updateMenuTitle();
        const { toggleCollapseStatus, isCallapsed } = this.props;
        return (
            <div className='tw-w-full tw-h-[70px] tw-px-[30px] tw-flex tw-justify-between tw-items-center tw-px-[30px] tw-shadow-xl'>
                {showLoading && <Loading />}
                <div className='tw-flex tw-items-center'>
                    <div className='tw-mr-[30px] tw-h-[24px] tw-flex tw-flex-col tw-justify-center hamburger' onClick={toggleCollapseStatus}>
                        {isCallapsed ?
                            <ArrowRightIcon size={20} color={ColorConstants.brand.primary} /> : <React.Fragment>
                            <div className='tw-w-[22px] tw-h-[2px] tw-rounded-[5px] tw-bg-brand-primary tw-mb-[4px] line'></div>
                            <div className='tw-w-[22px] tw-h-[2px] tw-rounded-[5px] tw-bg-brand-primary tw-mb-[4px] line'></div>
                            <div className='tw-w-[12px] tw-h-[2px] tw-rounded-[5px] tw-bg-brand-primary line'></div>
                        </React.Fragment>
                        }
                    </div>
                    <div className='tw-text-[20px] tw-font-[600]'>{activeMenuTitle}</div>
                </div>
                <div className='tw-flex tw-items-center'>
                    {this.renderUserProfileDropdownList()}
                </div>

            </div>
        );
    }
}

export default Header;
