import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const registerUser = (params) => {
    return api.post(
        `${ApiEndpointConstants.REGISTER}`,
        params,
    );
}

export const loginUser = (username, password, code) => {
    return api.post(
        `${ApiEndpointConstants.LOGIN}`,
        { username, password, code }
    );
}

export const logout = () => {
    return api.post(
        `${ApiEndpointConstants.LOGOUT}`
    );
}

export const getUserInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.USER_DETAIL}`, {id});
}

export const editUserInfoById = (params) => {
    if (params.password === '') {
        delete params.password;
    }
    return api.post(
        `${ApiEndpointConstants.EDIT_USER}`, params);
}

export const createUser = (params) => {
    const {username, password, tgid, email, mobile, point, group_id} = params;
    return api.post(
        `${ApiEndpointConstants.EDIT_USER}`, {
            username,
            password,
            tgid,
            email,
            mobile,
            point,
            group_id
        });
}

export const fetchUserProfileWithAccessToken = () => {
    return api.post(ApiEndpointConstants.ME);
}

export const fetchUserList = (page, per_page, keywords) => {
    return api.post(
        `${ApiEndpointConstants.USER_LIST}`,
        { page, per_page, keywords },
    );
}

export const fetchUnpagedUserList = () => {
    return api.post(
        `${ApiEndpointConstants.UNPAGED_USER_LIST}`
    );
}

export const fetchVerificationCode = (params) => {
    return api.post(
        `${ApiEndpointConstants.TELEGRAM_CODE}`,
        params,
    );
}

export const deleteUser = (id) => {
    return api.post(
        `${ApiEndpointConstants.DELETE_USER}`,
        {id},
    );
}