import React from 'react';
import Colors from '../constants/ColorConstants';

const TelegramIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="3171" width={size} height={size} {...props}>
    <path d="M957.4 197.2l-135.2 637.6c-10.2 45-36.8 56.2-74.6 35l-206-151.8-99.4 95.6c-11 11-20.2 20.2-41.4 20.2l14.8-209.8 381.8-345c16.6-14.8-3.6-23-25.8-8.2L299.6 568 96.4 504.4c-44.2-13.8-45-44.2 9.2-65.4L900.4 132.8c36.8-13.8 69 8.2 57 64.4z" p-id="3172" ></path>
  </svg>
);

export default TelegramIcon