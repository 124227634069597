import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';

// --- Components --- //
import Header from '../components/common/header';

import DashboardPage from '../pages/DashboardPage';

import UserPage from '../pages/user/UserPage';
import UserEditPage from '../pages/user/UserEditPage';

import PaymentAccountPage from '../pages/paymentAccount/PaymentAccountPage';
import PaymentAccountEditPage from '../pages/paymentAccount/PaymentAccountEditPage';

import WalletPage from '../pages/wallet/index';

import PayoutListPage from '../pages/payout/PayoutListPage';
import NewPayoutPage from '../pages/payout/NewPayoutPage';


import BillListPage from '../pages/bill/BillListPage';
import BillEditPage from '../pages/bill/BillEditPage';

import SettleListPage from '../pages/settle/SettleListPage';
import NewSettlePage from '../pages/settle/NewSettlePage';

import RechargePage from '../pages/recharge/RechargePage';

// --- Constants --- //
import PagePathConstants from '../constants/router/PagePathConstants';

// --- Actions --- //
import * as userActionCreator from '../actions/userActionCreator';
import * as routeActionCreator from '../actions/routeActionCreator';

// --- Icons --- //
import TriangleRightIcon from '../icons/TriangleRightIcon';

// --- Selectors --- //
import * as UserSelectors from '../selectors/UserSelectors';
import * as RouteSelectors from '../selectors/RouteSelectors';

// --- Factories --- //
import WithLoggedInRedirectionFactory from '../factories/WithLoggedInRedirectionFactory';
import UserHelper from '../helpers/UserHelper';

// --- Icons --- //
import LogoSmall from '../images/logo-small.png';
import LogoDesktop from '../images/logo-desktop.png';
import I18N from '../i18n';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';


class EntrancePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showProfileDropdown: false,
      showPaymentTypeDropdown: false,
      isCallapsed: false,
    }
    this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this);
    this.logout = this.logout.bind(this);
    this.routeToSpecPage = this.routeToSpecPage.bind(this);
    this.updateActiveRoute = this.updateActiveRoute.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleCollapseStatus = this.toggleCollapseStatus.bind(this);
  }

  componentDidMount() {
    this.updateActiveRoute();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, navRouterList } = nextProps;
    if ((location.pathname && location.pathname !== this.props.location.pathname)) {
      this.updateActiveRoute(nextProps);
    }
  }

  updateActiveRoute(props) {
    let { navRouterList, location, routeActions } = props || this.props;
    navRouterList = navRouterList.map(nav => {
      if (location.pathname.indexOf(nav.path) >= 0) {
        nav.isOpen = true;
      }
      return nav;
    });
    routeActions && routeActions.updateMenuStatus(navRouterList);

  }

  toggleMenu(index, menu) {
    if (!menu.children) {
      this.routeToSpecPage(menu.path);
    } else {
      this.routeToSpecPage(menu.children[0].path)
    }
    let { navRouterList } = this.props;
    navRouterList = navRouterList.map((item, i) => {
      if (index === i) {
        item.isOpen = !item.isOpen
      } else {
        item.isOpen = false
      }
      return item;
    });
    const { routeActions } = this.props;
    routeActions && routeActions.toggleMenuStatus(navRouterList);
  }

  toggleCollapseStatus() {
    this.setState({ isCallapsed: !this.state.isCallapsed });
  }

  routeToSpecPage(url) {
    const { history } = this.props;
    url && history && history.push(url);
  }

  toggleProfileDropdown() {
    this.setState({ showProfileDropdown: !this.state.showProfileDropdown });
  }

  togglePaymenTypeDropdown() {
    this.setState({ showPaymentTypeDropdown: !this.state.showPaymentTypeDropdown });
  }

  logout() {
    const { userActions, history } = this.props;
    userActions && userActions.logout()
      .then(() => {
        history && history.push(PagePathConstants.LOGIN_PAGE);
      });
  }

  switchPaymentType(type) {
    const { userActions } = this.props;
    userActions && userActions.updatePaymentTypeAction(type);
  }

  render() {
    const { history, currentUser, navRouterList } = this.props;
    const pathname = history.location && history.location.pathname;
    const isAdmin = UserHelper.isAdminUser(currentUser);
    const { isCallapsed } = this.state;
    return <div className='tw-w-full tw-h-full tw-flex  tw-bg-bg-color tw-text-body-text-primary'>
      <div className={classNames('tw-w-[220px] tw-h-full tw-pb-[30px] tw-shadow-xl tw-border-r-[1px] tw-text-body-text-secondary', { 'collapse-menu': isCallapsed })}>
        <div className='tw-w-full tw-py-[20px] tw-px-[20px] tw-h-[70px] tw-flex tw-justify-start tw-items-center tw-text-[18px] tw-font-bold tw-shadow-xl'>

          {isCallapsed ? <img className='tw-mx-auto tw-h-[25px]' src={LogoSmall} alt='logo' /> : <img className='tw-mx-auto tw-h-[25px]' src={LogoDesktop} alt='logo'/>}
          {/* <svg class="tw-w-[42px] tw-h-[42px]" viewBox="0 0 53 53"><path d="M21.6348 8.04782C21.6348 5.1939 23.9566 2.87204 26.8105 2.87204H28.6018L28.0614 1.37003C27.7576 0.525342 26.9616 0 26.1132 0C25.8781 0 25.639 0.0403711 25.4052 0.125461L7.3052 6.7133C6.22916 7.105 5.67535 8.29574 6.06933 9.37096L7.02571 11.9814H21.6348V8.04782Z" fill="#759DD9"></path><path d="M26.8105 5.97754C25.6671 5.97754 24.7402 6.90442 24.7402 8.04786V11.9815H42.8555V8.04786C42.8555 6.90442 41.9286 5.97754 40.7852 5.97754H26.8105Z" fill="#F8A961"></path><path class="svg-logo-primary-path" d="M48.3418 41.8457H41.0957C36.8148 41.8457 33.332 38.3629 33.332 34.082C33.332 29.8011 36.8148 26.3184 41.0957 26.3184H48.3418V19.2275C48.3418 16.9408 46.4879 15.0869 44.2012 15.0869H4.14062C1.85386 15.0869 0 16.9408 0 19.2275V48.8594C0 51.1462 1.85386 53 4.14062 53H44.2012C46.4879 53 48.3418 51.1462 48.3418 48.8594V41.8457Z" fill="#886CC0"></path><path class="svg-logo-primary-path" d="M51.4473 29.4238H41.0957C38.5272 29.4238 36.4375 31.5135 36.4375 34.082C36.4375 36.6506 38.5272 38.7402 41.0957 38.7402H51.4473C52.3034 38.7402 53 38.0437 53 37.1875V30.9766C53 30.1204 52.3034 29.4238 51.4473 29.4238ZM41.0957 35.6348C40.2382 35.6348 39.543 34.9396 39.543 34.082C39.543 33.2245 40.2382 32.5293 41.0957 32.5293C41.9532 32.5293 42.6484 33.2245 42.6484 34.082C42.6484 34.9396 41.9532 35.6348 41.0957 35.6348Z" fill="#886CC0"></path></svg>
          {!isCallapsed && <div className='tw-ml-[20px] tw-text-[20px] tw-font-[600] lora-font'>Hongsen</div>} */}
        </div>
        {!isCallapsed && <div className='tw-mt-[20px] tw-mx-[20px] tw-px-[12px] tw-py-[12px] tw-rounded-[15px] tw-flex tw-justify-start tw-items-center tw-shadow-around'>
          <div className='tw-w-[46px] tw-h-[46px] tw-rounded-full tw-overflow-hidden' style={{ backgroundImage: `url(${currentUser && currentUser.img && (BASE_URL + currentUser.img)})`, backgroundSize: '100%', backgroundPosition: 'center' }}></div>
          <div className='tw-w-[calc(100%-46px)] tw-pl-[10px] gotham-font'>
            <div className='tw-text-[16px] tw-font-bold'>{I18N.getText('say-hi')} </div>
            <div className='tw-w-full tw-text-[14px] tw-line-clamp-1 tw-whitespace-nowrap tw-text-ellipsis tw-block'>{currentUser && currentUser.username}</div>
          </div>
        </div>}

        <div className='tw-w-full tw-py-[30px]'>
          {navRouterList.map((menu, index) => {
            return (<div className='hs-route-menu-container' key={menu.path}>
              <div className={classNames(`tw-mx-auto tw-w-full tw-pl-[20px] tw-pr-[30px] tw-h-[50px] tw-flex tw-justify-between tw-items-center tw-text-[16px] tw-relative route-menu`, { 'hs-open-menu': menu.isOpen })} onClick={() => this.toggleMenu(index, menu)}>
                {!isCallapsed && <i className='tw-absolute tw-left-0 tw-top-0 tw-bg-none tw-w-[0px] tw-h-full'></i>}
                <div className='tw-flex tw-items-center'>
                  <i className={`${menu.icon} tw-mt-[1px]`}></i>
                  {!isCallapsed && <div className='tw-ml-[20px] tw-text-[14px]'>{menu.text}</div>}
                </div>
                {!isCallapsed && menu.children && <TriangleRightIcon size={14} className="arrow-icon tw-w-[14px] tw-h-[14px]" />}
              </div>
              <Collapse isOpen={menu.isOpen} navbar>
                {menu.children && menu.children.map(childMenu => {
                  return <div key={childMenu.path} className={classNames('tw-w-full tw-pl-[52px] tw-py-[10px] tw-text-[#596b8c] tw-text-[15px]', { ' tw-text-brand-primary': pathname === childMenu.path })} onClick={() => this.routeToSpecPage(childMenu.path)}>{childMenu.text}</div>
                })}
              </Collapse>
            </div>)
          })}
        </div>
      </div>
      <div className={classNames('tw-w-[calc(100%-220px)]', { 'full-route': isCallapsed })}>
        <Header {...this.props} toggleCollapseStatus={this.toggleCollapseStatus} isCallapsed={isCallapsed} />
        <div className='tw-w-full tw-h-[calc(100%-70px)] tw-overflow-y-scroll scrollbar-hidden'>
          <Switch>


            {isAdmin && <Route path={PagePathConstants.USER} component={UserPage} exact />}
            {isAdmin && <Route path={PagePathConstants.USER_EDIT} component={UserEditPage} />}

            <Route path={PagePathConstants.PAYMENT_ACCOUNT} component={PaymentAccountPage} exact />
            <Route path={PagePathConstants.PAYMENT_ACCOUNT_EDIT} component={PaymentAccountEditPage} />

            <Route path={PagePathConstants.WALLET} component={WalletPage} />

            <Route path={PagePathConstants.PAYOUT} component={PayoutListPage} exact />
            <Route path={PagePathConstants.PAYOUT_EDIT} component={NewPayoutPage} />

            <Route path={PagePathConstants.BILL} component={BillListPage} exact />
            <Route path={PagePathConstants.BILL_EDIT} component={BillEditPage} />

            <Route path={PagePathConstants.SETTLE_ACCOUNT} component={SettleListPage} exact />
            <Route path={PagePathConstants.SETTLE_BY_ACCOUNT} component={NewSettlePage} />
            
            <Route path={PagePathConstants.RECHARGE} component={RechargePage} />

            <Route path={PagePathConstants.DASHBOARD} component={DashboardPage} />

            <Redirect exact to={PagePathConstants.DASHBOARD} />
          </Switch>
        </div>
      </div>

    </div>;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: UserSelectors.selectCurrentUser(state),
    navRouterList: RouteSelectors.selectRouteList(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActionCreator, dispatch),
    routeActions: bindActionCreators(routeActionCreator, dispatch),
  };
}

export default WithLoggedInRedirectionFactory(connect(mapStateToProps, mapDispatchToProps)(EntrancePage));