import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const fetchGraphData = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_GRAPH}`,
        params,
    );
}


export const fetchPayoutGraphData = (params) => {
    return api.post(
        `${ApiEndpointConstants.PAYOUT_GRAPH_DATA}`,
        params,
    );
}

export const fetchPaymentAccountNumberGraphData = (params) => {
    return api.post(
        `${ApiEndpointConstants.PAYMENT_ACCOUNT_GRAPH_DATA}`,
        params,
    );
}

export const fetchPaymentAccountNumber = (params) => {
    return api.post(
        `${ApiEndpointConstants.PAYMENT_ACCOUNT_NUMBER}`
    );
}
